import type { MaybeRef } from '@vueuse/core';
import { get } from '@vueuse/core';
import orderBy from 'lodash/orderBy';
import { formatMinDecimalPlace } from '@/core/lib/numberFormat';
import type { IOdds, IPrice } from '@/interface/IOdds';
import { BetBuilderDisplayType, MarketGroup, MarketType, OddsOption, PriceStyle } from '@/interface/enum';

export const marketType1X2 = [
    MarketType._1X2,
    MarketType.FH_1X2,
    MarketType.LiveScore,
    MarketType.FH_LiveScore,
];

const betBuilderMarketType1x2 = [
    BetBuilderDisplayType._1X2,
    BetBuilderDisplayType.FH_1X2,
    BetBuilderDisplayType.LiveScore,
    BetBuilderDisplayType.FH_LiveScore,
];

export function is1X2(marketType: MarketType) {
    return marketType1X2.includes(marketType);
}

export function isBetBuilder1X2(marketType: BetBuilderDisplayType) {
    return betBuilderMarketType1x2.includes(marketType);
}

export function has1X2(oddsList: MaybeRef<(IOdds | null)[]>) {
    return get(oddsList).filter(x => x && marketType1X2.includes(x.marketType)).length > 0;
}

const handicapMarketTypes = [
    MarketType.Handicap,
    MarketType.FH_Handicap,
];

const betBuilderHandicapMarketTypes = [
    BetBuilderDisplayType.Handicap,
    BetBuilderDisplayType.FH_Handicap,
];

export function isHandicap(marketType: MarketType) {
    return handicapMarketTypes.includes(marketType);
}

export function isBetBuilderHandicap(marketType: BetBuilderDisplayType) {
    return betBuilderHandicapMarketTypes.includes(marketType);
}

const marketTypeOU = [
    MarketType.OverUnder,
    MarketType.FH_OverUnder,
];

const betBuilderMarketTypeOU = [
    BetBuilderDisplayType.OverUnder,
    BetBuilderDisplayType.FH_OverUnder,
];

export function isOU(marketType: MarketType) {
    return marketTypeOU.includes(marketType);
}

export function isBetBuilderOU(marketType: BetBuilderDisplayType) {
    return betBuilderMarketTypeOU.includes(marketType);
}

const marketTypeOE = [
    MarketType.OddEven,
    MarketType.FH_OddEven,
];

const betBuilderMarketTypeOE = [
    BetBuilderDisplayType.OddEven,
    BetBuilderDisplayType.FH_OddEven,
];

export function isOE(marketType: MarketType) {
    return marketTypeOE.includes(marketType);
}

export function isBetBuilderOE(marketType: BetBuilderDisplayType) {
    return betBuilderMarketTypeOE.includes(marketType);
}

const marketTypeCS = [
    MarketType.CorrectScore,
    MarketType.FH_CorrectScore,
];

export function isCS(oddsOrMarketType: MaybeRef<IOdds> | MarketType) {
    return marketTypeCS.includes(getMarketType(get(oddsOrMarketType)));
}

export function hasYesNoMarketGroup(oddsList: MaybeRef<IOdds[]>) {
    return get(oddsList).filter(x => x.eventResult.marketGroup.isYesNo).length > 0;
}

export const nextGoalMarketGroups = [
    MarketGroup.FirstGoal,
    MarketGroup.SecondGoal,
    MarketGroup.ThirdGoal,
    MarketGroup.ForthGoal,
    MarketGroup.FifthGoal,
    MarketGroup.SixthGoal,
    MarketGroup.SeventhGoal,
    MarketGroup.EighthGoal,
    MarketGroup.NinthGoal,
    MarketGroup.TenthGoal,
];

export function isNextGoal(oddsOrMarketGroupId: IOdds | MarketGroup): boolean {
    return nextGoalMarketGroups.includes(getMarketGroupId(oddsOrMarketGroupId));
}

export function isHighestOpeningPartnership(marketGroupId: MarketGroup) {
    return marketGroupId === MarketGroup.HighestOpeningPartnership;
}

export function isMainMarket(oddsOrMarketGroupId: IOdds | MarketGroup) {
    return getMarketGroupId(oddsOrMarketGroupId) === MarketGroup.Main;
}

export function isNewBetType(oddsOrMarketGroupId: IOdds | MarketGroup) {
    return !isMainMarket(oddsOrMarketGroupId);
}

export function isFastMarket(oddsOrMarketGroupId: IOdds | MarketGroup) {
    return isOneMinFastMarket(oddsOrMarketGroupId)
        || isFiveMinFastMarket(oddsOrMarketGroupId);
}

export function isOneMinFastMarket(oddsOrMarketGroupId: IOdds | MarketGroup) {
    const marketGroupId = getMarketGroupId(oddsOrMarketGroupId);

    return marketGroupId >= MarketGroup.FirstOneMinuteFastMarket
        && marketGroupId <= MarketGroup.LastOneMinuteFastMarket;
}

export function isFiveMinFastMarket(oddsOrMarketGroupId: IOdds | MarketGroup) {
    const marketGroupId = getMarketGroupId(oddsOrMarketGroupId);

    return marketGroupId >= MarketGroup.FirstFiveMinuteFastMarket
        && marketGroupId <= MarketGroup.LastFiveMinuteFastMarket;
}

export function isTotalCorner(betTypeGroupId: MarketGroup) {
    return betTypeGroupId === MarketGroup.TotalCorner;
}

export function isShowPoint(marketType: MarketType, marketGroup: MarketGroup) {
    if (isNextGoal(marketGroup)) return false;
    return isHandicap(marketType) || isOU(marketType);
}

export function shouldNegatePoint(point: number, marketType: MarketType, option: OddsOption) {
    if (point === 0) return false;
    return isHandicap(marketType) && option === OddsOption.Home;
}

export function getOddsPoint(odds: IOdds, price: IPrice) {
    if (!isShowPoint(odds.marketType, getMarketGroupId(odds))) return null;

    const index = odds.prices.findIndex(x => x.option === price.option);
    const formattedPoint = formatPoint(odds.point);

    // first option (home/over)
    if (index === 0 && odds.point >= 0) return formattedPoint;

    // second option (away/under)
    if (index === 1 && odds.point < 0) return formattedPoint;

    return '';
}

export function getTag(odds: IOdds, price: IPrice) {
    if (isOU(odds.marketType)) {
        return price.option === 'h' ? 'O' : 'U';
    }
    if (isOE(odds.marketType)) {
        return price.option === 'h' ? 'O' : 'E';
    }
    if (is1X2(odds.marketType)) {
        return price.option.toUpperCase();
    }
    return null;
}

export function getMarketType(oddsOrMarketType: IOdds | MarketType): MarketType {
    return typeof oddsOrMarketType === 'number'
        ? oddsOrMarketType
        : oddsOrMarketType.marketType;
}

export function getMarketGroupId(oddsOrMarketGroupId: IOdds | MarketGroup): MarketGroup {
    return typeof oddsOrMarketGroupId === 'number'
        ? oddsOrMarketGroupId
        : oddsOrMarketGroupId.eventResult.marketGroup.id;
}

const fastMarketDisplayNameLookup: Record<number, string> = {
    [MarketGroup.FirstOneMinuteFastMarket]: '00:00 - 00:59',
    639: '01:00 - 01:59',
    640: '02:00 - 02:59',
    641: '03:00 - 03:59',
    642: '04:00 - 04:59',
    643: '05:00 - 05:59',
    644: '06:00 - 06:59',
    645: '07:00 - 07:59',
    646: '08:00 - 08:59',
    647: '09:00 - 09:59',
    648: '10:00 - 10:59',
    649: '11:00 - 11:59',
    650: '12:00 - 12:59',
    651: '13:00 - 13:59',
    652: '14:00 - 14:59',
    653: '15:00 - 15:59',
    654: '16:00 - 16:59',
    655: '17:00 - 17:59',
    656: '18:00 - 18:59',
    657: '19:00 - 19:59',
    658: '20:00 - 20:59',
    659: '21:00 - 21:59',
    660: '22:00 - 22:59',
    661: '23:00 - 23:59',
    662: '24:00 - 24:59',
    663: '25:00 - 25:59',
    664: '26:00 - 26:59',
    665: '27:00 - 27:59',
    666: '28:00 - 28:59',
    667: '29:00 - 29:59',
    668: '30:00 - 30:59',
    669: '31:00 - 31:59',
    670: '32:00 - 32:59',
    671: '33:00 - 33:59',
    672: '34:00 - 34:59',
    673: '35:00 - 35:59',
    674: '36:00 - 36:59',
    675: '37:00 - 37:59',
    676: '38:00 - 38:59',
    677: '39:00 - 39:59',
    678: '40:00 - 40:59',
    679: '41:00 - 41:59',
    680: '42:00 - 42:59',
    681: '43:00 - 43:59',
    682: '44:00 - 44:59',
    683: '45:00 - 45:59',
    684: '46:00 - 46:59',
    685: '47:00 - 47:59',
    686: '48:00 - 48:59',
    687: '49:00 - 49:59',
    688: '50:00 - 50:59',
    689: '51:00 - 51:59',
    690: '52:00 - 52:59',
    691: '53:00 - 53:59',
    692: '54:00 - 54:59',
    693: '55:00 - 55:59',
    694: '56:00 - 56:59',
    695: '57:00 - 57:59',
    696: '58:00 - 58:59',
    697: '59:00 - 59:59',
    698: '60:00 - 60:59',
    699: '61:00 - 61:59',
    700: '62:00 - 62:59',
    701: '63:00 - 63:59',
    702: '64:00 - 64:59',
    703: '65:00 - 65:59',
    704: '66:00 - 66:59',
    705: '67:00 - 67:59',
    706: '68:00 - 68:59',
    707: '69:00 - 69:59',
    708: '70:00 - 70:59',
    709: '71:00 - 71:59',
    710: '72:00 - 72:59',
    711: '73:00 - 73:59',
    712: '74:00 - 74:59',
    713: '75:00 - 75:59',
    714: '76:00 - 76:59',
    715: '77:00 - 77:59',
    716: '78:00 - 78:59',
    717: '79:00 - 79:59',
    718: '80:00 - 80:59',
    719: '81:00 - 81:59',
    720: '82:00 - 82:59',
    721: '83:00 - 83:59',
    722: '84:00 - 84:59',
    723: '85:00 - 85:59',
    724: '86:00 - 86:59',
    725: '87:00 - 87:59',
    726: '88:00 - 88:59',
    [MarketGroup.LastOneMinuteFastMarket]: '89:00 - 89:59',
    [MarketGroup.FirstFiveMinuteFastMarket]: '00:00 - 04:59',
    729: '05:00 - 09:59',
    730: '10:00 - 14:59',
    731: '15:00 - 19:59',
    732: '20:00 - 24:59',
    733: '25:00 - 29:59',
    734: '30:00 - 34:59',
    735: '35:00 - 39:59',
    736: '40:00 - 44:59',
    737: '45:00 - 49:59',
    738: '50:00 - 54:59',
    739: '55:00 - 59:59',
    740: '60:00 - 64:59',
    741: '65:00 - 69:59',
    742: '70:00 - 74:59',
    743: '75:00 - 79:59',
    744: '80:00 - 84:59',
    [MarketGroup.LastFiveMinuteFastMarket]: '85:00 - 89:59',
};

export function getFastMarketDisplayName(oddsOrMarketGroupId: IOdds | MarketGroup): string {
    return fastMarketDisplayNameLookup[getMarketGroupId(oddsOrMarketGroupId)] || '';
}

const PriceStyleDisplayMapping: Record<PriceStyle, string> = {
    [PriceStyle.Unknown]: '',
    [PriceStyle.Malay]: 'M',
    [PriceStyle.Indo]: 'I',
    [PriceStyle.Euro]: 'E',
    [PriceStyle.HK]: 'H',
};

export function convertToPriceStyleDisplay(priceStyle: PriceStyle): string {
    return PriceStyleDisplayMapping[priceStyle];
}

export function convertToEuroPrice(originalPrice: number, priceStyle: PriceStyle): number {
    if (priceStyle === PriceStyle.Euro) return originalPrice;

    let price = 0;
    if (priceStyle === PriceStyle.HK) {
        price = originalPrice + 1;
    } else if (originalPrice < 0) {
        price = 1 / -originalPrice + 1;
    } else if (originalPrice > 0) {
        price = originalPrice + 1;
    }
    return Math.floor(parseFloat((price * 1000).toFixed(6))) / 1000;
}

export function formatPoint(point: number): string {
    const absPoint = Math.abs(point);
    const integer = ~~absPoint;
    const fraction = absPoint - integer;

    if (fraction > 0) {
        if (fraction < 0.5) {
            return `${integer}-${(integer + 0.5).toFixed(1)}`;
        }

        if (fraction > 0.5) {
            return `${(integer + 0.5).toFixed(1)}-${(integer + 1)}`;
        }
        return absPoint.toFixed(2);
    }
    return absPoint.toFixed(1);
}

export function formatPointEuro(point: number, marketType: MarketType, option: OddsOption) {
    const newPoint = shouldNegatePoint(point, marketType, option)
        ? -point
        : point;
    const fraction = Math.abs(point % 1);

    const points = fraction === 0 || fraction === 0.5
        ? [newPoint]
        : [newPoint - 0.25, newPoint + 0.25];

    return orderBy(points, point => Math.abs(point))
        .map(point => (isHandicap(marketType) && point > 0
            ? `+${point.toFixed(1)}`
            : point.toFixed(1)));
}

export function formatPrice(price: number, marketType: MarketType) {
    return formatMinDecimalPlace(price, isCS(marketType) ? 0 : 2);
}

export function isValidOdds(odds: IOdds) {
    if (is5050Market(odds.marketType) || is1X2(odds.marketType) || odds.marketType === MarketType.MoneyLine) {
        return odds.prices.every(x => x.price !== 0);
    }

    return true;
}

export function is5050Market(marketType: MarketType) {
    const marketType5050 = [
        MarketType.Handicap,
        MarketType.FH_Handicap,
        MarketType.OddEven,
        MarketType.FH_OddEven,
        MarketType.OverUnder,
        MarketType.FH_OverUnder,
    ];

    return marketType5050.includes(marketType);
}

export function isInvalidLiveMp1X2Odds(odds: IOdds, isMixParlayMode: boolean) {
    return isMixParlayMode
        && odds.isLive
        && (odds.marketType === MarketType._1X2 || odds.marketType === MarketType.FH_1X2)
        && odds.eventResult.liveHomeScore - odds.eventResult.liveAwayScore !== 0
        && odds.prices.some(x => x.price < 1.5);
}
