import type { MaybeRef } from '@vueuse/core';
import { get } from '@vueuse/core';
import { computed } from 'vue';
import { OddsDisplayContext } from '@/components/oddsDisplay/OddsDisplayContext';
import { OpenMoreMarketContext } from '@/components/oddsDisplay/matchDisplay/OpenMoreMarketContext';
import { getMoreMarketCount } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
import { deepCompareComputed } from '@/composable/deepCompareComputed';
import { isMainMarket } from '@/core/lib/oddsHelper';
import type { IEvent } from '@/interface/IEvent';
import type { MarketGroup, OddsDisplayMode } from '@/interface/enum';

/**
 * A simplified version of useOpenMoreMarket, without any reactivity involved
 */
export function getIsOpenMoreMarket(oddsDisplayMode: OddsDisplayMode, event: IEvent, marketGroupId: MarketGroup) {
    const {
        liveOpenMoreMarketMatchResultIds,
        nonLiveOpenMoreMarketMatchResultIds,
    } = OpenMoreMarketContext.inject();

    const eventResultId = event.eventResults.find(r => r.marketGroup.id === marketGroupId)?.id;
    if (!eventResultId) return false;

    const moreMarketCount = getMoreMarketCount(oddsDisplayMode, event, marketGroupId);
    if (moreMarketCount <= 0) return false;

    const isLive = event.isLive;
    const ids = isLive
        ? liveOpenMoreMarketMatchResultIds.value
        : nonLiveOpenMoreMarketMatchResultIds.value;
    return ids.includes(eventResultId);
}

export function useOpenMoreMarket(oddsDisplayMode: OddsDisplayMode, event: MaybeRef<IEvent>, marketGroupId: MarketGroup) {
    const {
        liveOpenMoreMarketMatchResultIds,
        setLiveOpenMoreMarketMatchResultIds,
        nonLiveOpenMoreMarketMatchResultIds,
        setNonLiveOpenMoreMarketMatchResultIds,
    } = OpenMoreMarketContext.inject();

    const {
        betBuilderMatchCache,
        betBuilderExistsCache,
        matchListInBetBuilderMode,
    } = OddsDisplayContext.inject();

    /**
     * Bet builder caches change frequently, so we need to use deepCompareComputed to prevent unnecessary reactivity update
     */

    const eventId = get(event).id;
    const eventResultId = computed(() => get(event).eventResults.find(r => r.marketGroup.id === marketGroupId)?.id);
    const isBetBuilderMatchExist = computed(() => betBuilderExistsCache.value.get(eventId) ?? false);
    const isInBetBuilderMode = deepCompareComputed(() => isMainMarket(marketGroupId) && isBetBuilderMatchExist.value && matchListInBetBuilderMode.value.includes(eventId));
    const moreMarketCount = deepCompareComputed(() => getMoreMarketCount(oddsDisplayMode, get(event), marketGroupId));
    const betBuilderMarketCount = deepCompareComputed(() => betBuilderMatchCache.value.get(eventId)?.markets.length ?? 0);
    const isOpenMoreMarket = computed(() => {
        if (!eventResultId.value) return false;

        if (!isInBetBuilderMode.value && moreMarketCount.value <= 0) return false;
        if (isInBetBuilderMode.value && betBuilderMarketCount.value <= 0) return false;

        const isLive = get(event).isLive;
        const ids = isLive
            ? liveOpenMoreMarketMatchResultIds.value
            : nonLiveOpenMoreMarketMatchResultIds.value;

        return ids.includes(eventResultId.value);
    });

    const toggleIsOpenMoreMarket = (value?: boolean) => {
        const _eventResultId = eventResultId.value;
        if (!_eventResultId) return;

        const isLive = get(event).isLive;
        const setIds = isLive
            ? setLiveOpenMoreMarketMatchResultIds
            : setNonLiveOpenMoreMarketMatchResultIds;

        if (value !== undefined) {
            if (value) {
                setIds(ids => [...ids, _eventResultId]);
            } else {
                setIds(ids => ids.filter(id => id !== _eventResultId));
            }
            return;
        }

        if (isOpenMoreMarket.value) {
            setIds(ids => ids.filter(id => id !== _eventResultId));
        } else {
            setIds(ids => [...ids, _eventResultId]);
        }
    };

    return {
        isOpenMoreMarket,
        toggleIsOpenMoreMarket,
    };
}
