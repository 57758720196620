import type { Ref } from 'vue';
import { computed } from 'vue';
import { getOddsTableColumns } from '@/components/oddsDisplay/oddsTableCalculation';
import type { IEvent } from '@/interface/IEvent';
import type { MarketGroup, OddsDisplayBlockType, OddsDisplayMode } from '@/interface/enum';
import { ColumnType, MarketType } from '@/interface/enum';

export const useMatchBodyCalculation = (oddsDisplayMode: OddsDisplayMode, match: Ref<IEvent>) => {
    const columns = computed(() => getOddsTableColumns(oddsDisplayMode, match.value.sportType, match.value.isLive));
    function getColumnWidth(key: OddsDisplayBlockType | MarketType, marketGroup?: MarketGroup) {
        return `${columns.value.find((x) => {
            if (x.type === ColumnType.Market) {
                return x.key === key && x.marketGroup === marketGroup;
            }
            return x.key === key;
        })?.width ?? 0}px`;
    }

    function getColumnWidthLimit(marketType: MarketType, marketGroup: MarketGroup) {
        return columns.value.find(x => x.type === ColumnType.Market && x.key === marketType && x.marketGroup === marketGroup)
            ?.innerWidthLimit
        ?? undefined;
    }
    const mapping: Partial<Record<MarketType, number>> = {
        [MarketType._1X2]: 3,
        [MarketType.FH_1X2]: 3,
        [MarketType.LiveScore]: 3,
        [MarketType.FH_LiveScore]: 3,
    };
    function getPriceCount(marketType: MarketType) {
        return mapping[marketType] ?? 2;
    }

    return {
        getColumnWidth,
        getColumnWidthLimit,
        getPriceCount,
    };
};
