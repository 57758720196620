import { useThrottleFn } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, watch } from 'vue';
import { OddsDisplayContext } from '@/components/oddsDisplay/OddsDisplayContext';
import { getDisplayMarketTypeInfos } from '@/components/oddsDisplay/marketTypeDisplayRule';
import { useEventEmitter } from '@/composable/useEventEmitter';
import { createMap, removeDuplicates } from '@/core/lib/array';
import { defineContext } from '@/core/lib/defineContext';
import { isNextGoal, nextGoalMarketGroups } from '@/core/lib/oddsHelper';
import { useBetBuilderExists } from '@/core/oddsApi/composable/useBetBuilderExists';
import { useBetBuilderMatch } from '@/core/oddsApi/composable/useBetBuilderMatch';
import { useEventOdds } from '@/core/oddsApi/composable/useEventOdds';
import { useOutrightLeagues } from '@/core/oddsApi/composable/useOutrightLeagues';
import { getOddsCategory, getOddsPresetFilterInputType } from '@/core/oddsApi/helpers';
import { EnumOddsMarketFilterType, EnumPresetFilterType } from '@/core/oddsApi/oddsApiType';
import { MarketGroup, MarketPage, SiteStyle, SportType } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

// each OddsDisplayMatchContext instance will only serve for one OddsDisplayMatch.vue
export const OddsDisplayMatchContext = defineContext('OddsDisplayMatch', (eventId: number, shouldLoadNewBetType: Ref<boolean>, shouldLoadOutright: Ref<boolean>) => {
    const {
        eventCache,
        marketPage,
        oddsDisplayMode,
        betBuilderExistsCache,
        betBuilderMatchCache,
        matchListInBetBuilderMode,
        setMatchListInBetBuilderMode,
    } = OddsDisplayContext.inject();
    const { priceStyle } = storeToRefs(useCustomerStore());

    /**
     * We intentionally not use computed here to reduce unnecessary reactivity update
     * You are only allowed to use properties on it that *will never change* here
     * Otherwise, you should use `event` computed property to get the latest value
     */
    const {
        isLive,
        sportType,
    } = eventCache.value.get(eventId)!;

    const event = computed(() => eventCache.value.get(eventId));
    const leagueIds = computed(() => event.value?.outrightIds ?? []);

    const displayMarketTypeInfos = computed(() => getDisplayMarketTypeInfos(sportType, isLive, oddsDisplayMode));
    const marketFilter = computed(() => (displayMarketTypeInfos.value.some(info => info.marketGroup !== MarketGroup.Main)
        ? EnumOddsMarketFilterType.All
        : EnumOddsMarketFilterType.MainMarket));

    const marketGroupIds = computed(() => {
        const marketGroups = displayMarketTypeInfos.value.map(info => info.marketGroup);
        const hasNextGoal = displayMarketTypeInfos.value.some(info => isNextGoal(info.marketGroup));
        if (hasNextGoal) {
            marketGroups.push(...nextGoalMarketGroups);
        }

        return removeDuplicates(marketGroups);
    });

    const { isBetBuilderEnabled } = storeToRefs(useToggleStore(SiteStyle.Asia));
    const betBuilderMarketPages = [MarketPage.Today, MarketPage.EarlyMarket, MarketPage.Live, MarketPage.MyFavorites];
    const isBetBuilderQueryEnabled = computed(() => isBetBuilderEnabled.value
        && sportType === SportType.Soccer
        && betBuilderMarketPages.includes(marketPage));
    const { isBetBuilderMatchExist: _isBetBuilderMatchExist } = useBetBuilderExists(eventId, isBetBuilderQueryEnabled);
    const isBetBuilderMatchExist = computed(() => _isBetBuilderMatchExist.value ?? betBuilderExistsCache.value.get(eventId) ?? false);
    watch(_isBetBuilderMatchExist, (value) => {
        if (value !== undefined) {
            betBuilderExistsCache.value.set(eventId, value);
        }
    });

    const isInBetBuilderMode = computed(() => isBetBuilderMatchExist.value && matchListInBetBuilderMode.value.includes(eventId));
    const setIsInBetBuilderMode = (newValue: boolean) => {
        if (newValue) {
            setMatchListInBetBuilderMode([...matchListInBetBuilderMode.value, eventId]);
        } else {
            setMatchListInBetBuilderMode(matchListInBetBuilderMode.value.filter(id => id !== eventId));
        }
    };

    const {
        oddsList,
        loaded,
        refetch,
    } = useEventOdds({
        eventId,
        presetFilter: isLive ? EnumPresetFilterType.Live : EnumPresetFilterType.NonLive,
        oddsCategory: getOddsCategory(marketPage),
        marketFilter,
        marketGroupIds,
        priceStyle,
    });

    const {
        oddsList: newBetTypeOddsList,
        loaded: newBetTypeLoaded,
        refetch: newBetTypeRefetch,
    } = useEventOdds({
        eventId,
        presetFilter: getOddsPresetFilterInputType(isLive),
        oddsCategory: getOddsCategory(marketPage),
        marketFilter: EnumOddsMarketFilterType.NewBetType,
        priceStyle,
        isActive: computed(() => shouldLoadNewBetType.value && !isInBetBuilderMode.value),
    });

    const {
        outrightLeagues,
        loaded: outrightLoaded,
        refetch: outrightLeagueRefetch,
    } = useOutrightLeagues({
        sportType,
        isLive,
        leagueIds,
        isActive: computed(() => shouldLoadOutright.value && !isInBetBuilderMode.value),
    });

    const oddsCache = computed(() => createMap([...oddsList.value, ...newBetTypeOddsList.value], o => o.id, o => o));

    const {
        betBuilderMatch: _betBuilderMatch,
        loaded: _betBuilderMatchLoaded,
        refetch: betBuilderMatchRefetch,
    } = useBetBuilderMatch(eventId, isInBetBuilderMode);
    const betBuilderMatchLoaded = computed(() => _betBuilderMatchLoaded.value || betBuilderMatchCache.value.has(eventId));
    const betBuilderMatch = computed(() => (_betBuilderMatchLoaded.value
        ? _betBuilderMatch.value
        : betBuilderMatchCache.value.get(eventId)));

    watch(_betBuilderMatch, (value) => {
        if (!value) return;
        betBuilderMatchCache.value.set(eventId, value);
    }, { deep: true });

    const throttledRefetch = useThrottleFn(betBuilderMatchRefetch, 5000, false, true);
    const eventEmitter = useEventEmitter();
    eventEmitter.on('oddsDisplay:refreshBetBuilderMatch', (refreshMatchId) => {
        if (refreshMatchId === null || refreshMatchId === eventId) {
            throttledRefetch();
        }
    });

    return {
        oddsList,
        loaded,
        refetch,

        newBetTypeOddsList,
        newBetTypeLoaded,
        newBetTypeRefetch,

        outrightLeagues,
        outrightLoaded,
        outrightLeagueRefetch,

        betBuilderMatch,
        betBuilderMatchLoaded,

        isInBetBuilderMode,
        setIsInBetBuilderMode,
        isBetBuilderMatchExist,

        oddsCache,
    };
});
