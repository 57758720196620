import { whenever } from '@vueuse/core';
import uniq from 'lodash/uniq';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { OddsDisplayBlockContext } from '@/components/oddsDisplay/OddsDisplayBlockContext';
import { getMoreMarketCount } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
import { useState } from '@/composable/useState';
import { defineContext } from '@/core/lib/defineContext';
import { isMainMarket } from '@/core/lib/oddsHelper';
import type { IEvent } from '@/interface/IEvent';
import type { OddsDisplayMode } from '@/interface/enum';
import { MarketGroup, SiteStyle } from '@/interface/enum';
import { useToggleStore } from '@/store/toggleStore';

export const OpenMoreMarketContext = defineContext('OpenMoreMarket', () => {
    const [liveOpenMoreMarketMatchResultIds, setLiveOpenMoreMarketMatchResultIds] = useState<number[]>([]);
    const [nonLiveOpenMoreMarketMatchResultIds, setNonLiveOpenMoreMarketMatchResultIds] = useState<number[]>([]);

    const { defaultOpenMoreMarketInLiveLeagueIds } = storeToRefs(useToggleStore(SiteStyle.Asia));
    const { defaultOpenMoreMarketInLiveBySportsIds } = storeToRefs(useToggleStore(SiteStyle.Asia));
    const { loaded, events, displayEvents, oddsDisplayMode } = OddsDisplayBlockContext.inject();

    const liveFilteredEvents = computed(() => displayEvents.value.filter(e => e.isLive));
    const nonLiveFilteredEvents = computed(() => displayEvents.value.filter(e => !e.isLive));
    const defaultOpenMoreIds = computed(() => liveFilteredEvents.value
        .filter(event => defaultOpenMoreMarketInLiveLeagueIds.value.includes(event.league.id))
        .flatMap(event => event.eventResults.filter(eventResult => getMoreMarketCount(oddsDisplayMode, event, eventResult.marketGroup.id) > 0))
        .map(eventResult => eventResult.id));

    const defaultLiveOpenMoreBySportsIds = computed(() => liveFilteredEvents.value
        .filter(event => defaultOpenMoreMarketInLiveBySportsIds.value.includes(event.sportType))
        .flatMap(event => event.eventResults.filter(eventResult => getMoreMarketCount(oddsDisplayMode, event, eventResult.marketGroup.id) > 0))
        .map(eventResult => eventResult.id));
    const defaultNonLiveOpenMoreBySportsIds = computed(() => nonLiveFilteredEvents.value
        .filter(event => defaultOpenMoreMarketInLiveBySportsIds.value.includes(event.sportType))
        .flatMap(event => event.eventResults.filter(eventResult => getMoreMarketCount(oddsDisplayMode, event, eventResult.marketGroup.id) > 0))
        .map(eventResult => eventResult.id));

    whenever(computed(() => loaded.value && events.value.some(x => x.eventResults.length > 0)), () => {
        const firstLiveMatchResultId = getFirstEventResultIdWithMoreMarket(oddsDisplayMode, liveFilteredEvents.value);
        setLiveOpenMoreMarketMatchResultIds(uniq([firstLiveMatchResultId, ...defaultOpenMoreIds.value, ...defaultLiveOpenMoreBySportsIds.value]));

        const firstNonLiveMatchResultId = getFirstEventResultIdWithMoreMarket(oddsDisplayMode, nonLiveFilteredEvents.value);
        setNonLiveOpenMoreMarketMatchResultIds(uniq([firstNonLiveMatchResultId, ...defaultNonLiveOpenMoreBySportsIds.value]));
    }, { immediate: true });

    return {
        liveOpenMoreMarketMatchResultIds,
        setLiveOpenMoreMarketMatchResultIds,
        nonLiveOpenMoreMarketMatchResultIds,
        setNonLiveOpenMoreMarketMatchResultIds,
    };
});

function getFirstEventResultIdWithMoreMarket(oddsDisplayMode: OddsDisplayMode, events: IEvent[]) {
    const firstEvent = events.find(event => getMoreMarketCount(oddsDisplayMode, event, MarketGroup.Main) > 0);
    if (!firstEvent) return 0;

    return firstEvent.eventResults.find(r => isMainMarket(r.marketGroup.id))?.id ?? 0;
}
