<template>
    <div
        v-for="matchResult in matchResults"
        v-bind:key="matchResult.betTypeGroupId"
        v-debug:matchResultId="matchResult.matchResultId"
        class="newBetTypeResult"
    >
        <NextGoalResult
            v-if="isNextGoal(matchResult.betTypeGroupId as MarketGroup)"
            v-bind:matchResults="nextGoalResults"
        />
        <NewBetTypeResult
            v-else
            v-bind:matchResult="matchResult"
        />
    </div>
    <BetBuilderResult
        v-if="betBuilderResult.length > 0"
        v-bind:marketResult="betBuilderResult"
    />
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import BetBuilderResult from './BetBuilderResult.vue';
    import NewBetTypeResult from '@/components/results/NewBetTypeResult.vue';
    import NextGoalResult from '@/components/results/NextGoalResult.vue';
    import { isNextGoal } from '@/core/lib/oddsHelper';
    import type { IBetBuilderResult, IResult } from '@/interface/IResult';
    import type { MarketGroup } from '@/interface/enum';

    const props = defineProps({
        result: {
            type: Object as PropType<IResult>,
            required: true,
        },
        betBuilderResult: {
            type: Object as PropType<IBetBuilderResult[]>,
            required: true,
        },
    });

    const matchResults = computed(() => {
        const firstNextGoal = props.result.newBetTypeMatchResults.find(result => isNextGoal(result.betTypeGroupId as MarketGroup));
        return props.result.newBetTypeMatchResults.filter(result => !(isNextGoal(result.betTypeGroupId as MarketGroup) && result.betTypeGroupId !== firstNextGoal?.betTypeGroupId));
    });

    const nextGoalResults = computed(() => props.result.newBetTypeMatchResults.filter(result => isNextGoal(result.betTypeGroupId as MarketGroup)));
</script>

<style lang="scss" scoped>
    @import "@/components/results/MoreResultBlock";
</style>
