import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { ref } from 'vue';
import { getDisplayMarketTypeInfos, isSingleMode, toBetBuilderMarketTypeInfos } from '@/components/oddsDisplay/marketTypeDisplayRule';
import { getMoreMarketCount } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
import { getIsOpenMoreMarket } from '@/components/oddsDisplay/matchDisplay/useOpenMoreMarket';
import { getMarketRule } from '@/components/oddsDisplay/moreMarket/betBuilderMarketRules';
import { padRight, rotate2dArray } from '@/core/lib/array';
import { getSelectionPoint } from '@/core/lib/betBuilderHelper';
import { getMarketGroupId, getOddsPoint, getTag, is1X2, isMainMarket, isNextGoal, nextGoalMarketGroups } from '@/core/lib/oddsHelper';
import type { SizeInfo } from '@/directives/resize';
import type { IEvent } from '@/interface/IEvent';
import type { MarketTypeInfo } from '@/interface/IMarketTypeInfo';
import type { IOdds } from '@/interface/IOdds';
import type { IOutrightLeague } from '@/interface/IOutrightLeague';
import type { IBetBuilderMarket } from '@/interface/betBuilder';
import {
    BetBuilderMarket, BetBuilderSelectionStatus, BetBuilderStatType, ColumnType, MarketGroup, MarketType, OddsDisplayBlockType, OddsDisplayMode, SportType,
} from '@/interface/enum';

const minimumOddsDisplayWidth = 829;
const oddsDisplayWidth = ref(minimumOddsDisplayWidth);

export const updateOddsDisplayWidth = ({ width }: SizeInfo) => {
    oddsDisplayWidth.value = width;
};

const orderBy12X = ['H', 'A', 'X'];

export const OddsDisplayBlockTypeToColumnWidthMapping: Record<OddsDisplayBlockType, number> = {
    [OddsDisplayBlockType.Time]: 66,
    [OddsDisplayBlockType.Team]: 180,
    [OddsDisplayBlockType.FullTime]: 0,
    [OddsDisplayBlockType.FirstHalf]: 0,
    [OddsDisplayBlockType.FirstQuarter]: 0,
    [OddsDisplayBlockType.More]: 50,
};

export const MarketTypeToColumnWidthMapping: Record<MarketType, number> = {
    [MarketType.Unknown]: 0,
    [MarketType.Handicap]: 90,
    [MarketType.OddEven]: 80,
    [MarketType.OverUnder]: 108,
    [MarketType.CorrectScore]: 0,
    [MarketType._1X2]: 64,
    [MarketType.TotalGoal]: 0,
    [MarketType.FH_Handicap]: 90,
    [MarketType.FH_1X2]: 64,
    [MarketType.FH_OverUnder]: 108,
    [MarketType.HTFT]: 0,
    [MarketType.MoneyLine]: 80,
    [MarketType.FH_OddEven]: 80,
    [MarketType.FGLG]: 0,
    [MarketType.FH_CorrectScore]: 0,
    [MarketType.DoubleChance]: 0,
    [MarketType.LiveScore]: 60,
    [MarketType.FH_LiveScore]: 40,
    [MarketType.Game]: 0,
    [MarketType.OutRight]: 0,
    [MarketType.MixParlay]: 0,
    [MarketType.BetBuilder]: 0,
};

export const MarketGroupToColumnWidthMapping: Partial<Record<MarketGroup, number>> = {
    [MarketGroup.FirstGoal]: 50,
    [MarketGroup.SecondGoal]: 50,
    [MarketGroup.ThirdGoal]: 50,
    [MarketGroup.ForthGoal]: 50,
    [MarketGroup.FifthGoal]: 50,
    [MarketGroup.SixthGoal]: 50,
    [MarketGroup.SeventhGoal]: 50,
    [MarketGroup.EighthGoal]: 50,
    [MarketGroup.NinthGoal]: 50,
    [MarketGroup.TenthGoal]: 50,
    [MarketGroup.HighestOpeningPartnership]: 126,
};

export const MarketToColumnWidthLimitMapping = new Map<MarketGroup, Map<MarketType, number>>([
    [MarketGroup.HighestOpeningPartnership, new Map([
        [MarketType._1X2, 80],
    ])],
]);

export const MarketTypeGroupingMapping = new Map<MarketType, OddsDisplayBlockType>([
    [MarketType.Handicap, OddsDisplayBlockType.FullTime],
    [MarketType._1X2, OddsDisplayBlockType.FullTime],
    [MarketType.OverUnder, OddsDisplayBlockType.FullTime],
    [MarketType.OddEven, OddsDisplayBlockType.FullTime],
    [MarketType.CorrectScore, OddsDisplayBlockType.FullTime],
    [MarketType.LiveScore, OddsDisplayBlockType.FullTime],
    [MarketType.MoneyLine, OddsDisplayBlockType.FullTime],
    [MarketType.FH_Handicap, OddsDisplayBlockType.FirstHalf],
    [MarketType.FH_1X2, OddsDisplayBlockType.FirstHalf],
    [MarketType.FH_OverUnder, OddsDisplayBlockType.FirstHalf],
    [MarketType.FH_OddEven, OddsDisplayBlockType.FirstHalf],
    [MarketType.FH_CorrectScore, OddsDisplayBlockType.FirstHalf],
    [MarketType.FH_LiveScore, OddsDisplayBlockType.FirstHalf],
]);

const NewBetTypeGroupingMapping = new Map<MarketGroup, OddsDisplayBlockType>([
    [MarketGroup.FirstQuarter, OddsDisplayBlockType.FirstQuarter],
]);

function getBlockColumnWidth(key: OddsDisplayBlockType, sportType: SportType, isLive: boolean) {
    if (key === OddsDisplayBlockType.Time && sportType === SportType.Cricket && isLive) {
        return 146;
    }
    return OddsDisplayBlockTypeToColumnWidthMapping[key];
}

function getMarketColumnWidth({ marketGroup, marketType }: MarketTypeInfo) {
    return marketGroup === MarketGroup.Main
        ? MarketTypeToColumnWidthMapping[marketType]
        : MarketGroupToColumnWidthMapping[marketGroup] ?? 0;
}

function getMarketColumnInnerWidthLimit({ marketGroup, marketType }: MarketTypeInfo): number | undefined {
    return MarketToColumnWidthLimitMapping.get(marketGroup)?.get(marketType);
}

type MarketColumn = {
    type: ColumnType.Market;
    key: MarketType;
    width: number;
    innerWidthLimit?: number;
    marketGroup: MarketGroup;
}

type BlockColumn = {
    type: ColumnType.Block;
    key: OddsDisplayBlockType;
    width: number;
    innerWidthLimit?: number;
    children?: MarketColumn[];
}

export type OddsTableColumn = MarketColumn | BlockColumn

function getBlockColumn(sportType: SportType, isLive: boolean, blockType: OddsDisplayBlockType, hasChildren = false): BlockColumn {
    return {
        type: ColumnType.Block,
        key: blockType,
        width: getBlockColumnWidth(blockType, sportType, isLive),
        children: hasChildren ? [] : undefined,
    };
}

function getMarketColumn(marketTypeInfo: MarketTypeInfo): MarketColumn {
    return {
        type: ColumnType.Market,
        key: marketTypeInfo.marketType,
        marketGroup: marketTypeInfo.marketGroup,
        width: getMarketColumnWidth(marketTypeInfo),
        innerWidthLimit: getMarketColumnInnerWidthLimit(marketTypeInfo),
    };
}

export function getMarketColumns(sportType: SportType, isLive: boolean, oddsDisplayMode: OddsDisplayMode): MarketColumn[] {
    const displayMarketTypeInfos = getDisplayMarketTypeInfos(sportType, isLive, oddsDisplayMode);
    return displayMarketTypeInfos.map(marketTypeInfo => getMarketColumn(marketTypeInfo));
}

/**
 * Get list of columns for odds table
 * @example
 * | Time | Team | Hdp | 1X2 |  OU | Hdp | 1X2 |  OU | More |
 * | ---- | ---- | --- | --- | --- | --- | --- | --- | ---- |
 * | 1H23 | Home | 1.5 | 0.2 | 0.8 | ... | ... | ... | .... |
 */
export function getOddsTableColumns(oddsDisplayMode: OddsDisplayMode, sportType: SportType, isLive: boolean): OddsTableColumn[] {
    const timeColumns = getBlockColumn(sportType, isLive, OddsDisplayBlockType.Time);
    const teamColumns = getBlockColumn(sportType, isLive, OddsDisplayBlockType.Team);
    const marketColumns = getMarketColumns(sportType, isLive, oddsDisplayMode);
    const moreColumns = getBlockColumn(sportType, isLive, OddsDisplayBlockType.More);

    const result = [
        timeColumns,
        teamColumns,
        ...marketColumns,
        moreColumns,
    ];
    if (isSingleMode(oddsDisplayMode)) {
        // adjust team column to use all remaining space
        const remainWidth = oddsDisplayWidth.value - result.reduce((acc, cur) => acc + cur.width, 0);
        marketColumns.forEach((x) => {
            x.width += (remainWidth / marketColumns.length);
            x.width /= 3;
        });
    } else {
        // adjust team column to use all remaining space
        const remainWidth = oddsDisplayWidth.value - minimumOddsDisplayWidth;
        result.forEach((x) => {
            x.width += (remainWidth * (x.width / minimumOddsDisplayWidth / 4));
        });
        teamColumns.width = oddsDisplayWidth.value - result.filter(({ key }) => key !== OddsDisplayBlockType.Team).reduce((acc, cur) => acc + cur.width, 0);
    }

    return result;
}

export function getOddsTableGroupedColumns(sportType: SportType, isLive: boolean, oddsDisplayMode: OddsDisplayMode): OddsTableColumn[] {
    return isSingleMode(oddsDisplayMode)
        ? getOddsTableGroupedColumnsSingle(sportType, isLive)
        : getOddsTableGroupedColumnsDouble(sportType, isLive);
}

function shouldUnderSameBlock(oddsTableColumn: OddsTableColumn, groupKey: OddsDisplayBlockType): oddsTableColumn is BlockColumn {
    return oddsTableColumn && oddsTableColumn.type === ColumnType.Block && oddsTableColumn.key === groupKey;
}

/**
 * Get list of grouped columns for odds table
 * @example
 * |   V  | Team |               Full Time               |    Half Time      → type = Block
 * |             | Hdp | HOME | AWAY | OU  | HOME | AWAY | Hdp  | ...       → type = Market
 * | ---- | ---- | --- | ---  | ---  | --- | ---  | ---  | ---- |
 * | 1H23 | Home | 1.5 | 0.2  |  0.8 | ... | ...  | ...  | .... |
 */
export function getOddsTableGroupedColumnsSingle(sportType: SportType, isLive: boolean): OddsTableColumn[] {
    const columns = getOddsTableColumns(OddsDisplayMode.Single, sportType, isLive);
    const result: OddsTableColumn[] = [];
    // eslint-disable-next-line consistent-return
    columns.forEach((column) => {
        if (column.type === ColumnType.Block) return result.push(column);
        const groupKey = isMainMarket(column.marketGroup)
            ? MarketTypeGroupingMapping.get(column.key)
            : NewBetTypeGroupingMapping.get(column.marketGroup);
        if (!groupKey) return result.push(column);

        const prev = result[result.length - 1];
        const groupColumn = shouldUnderSameBlock(prev, groupKey)
            ? prev
            : getBlockColumn(sportType, isLive, groupKey, true);
        // no children means new group
        if (groupColumn.children!.length === 0) result.push(groupColumn);
        groupColumn.children!.push(column);
        groupColumn.width += column.width * 3; // 3 columns per market
    });

    return result;
}

/**
 * Get list of grouped columns for odds table
 * @example
 * |   V  | Team |    Full Time    |    Half Time    | More |
 * |             | Hdp | 1X2 |  OU | Hdp | 1X2 |  OU |      |
 * | ---- | ---- | --- | --- | --- | --- | --- | --- | ---- |
 * | 1H23 | Home | 1.5 | 0.2 | 0.8 | ... | ... | ... | .... |
 */
export function getOddsTableGroupedColumnsDouble(sportType: SportType, isLive: boolean): OddsTableColumn[] {
    const columns = getOddsTableColumns(OddsDisplayMode.Double, sportType, isLive);
    const result: OddsTableColumn[] = [];
    // eslint-disable-next-line consistent-return
    columns.forEach((column) => {
        if (column.type === ColumnType.Block) return result.push(column);

        const groupKey = isMainMarket(column.marketGroup)
            ? MarketTypeGroupingMapping.get(column.key)
            : NewBetTypeGroupingMapping.get(column.marketGroup);
        if (!groupKey) return result.push(column);

        const prev = result[result.length - 1];
        const groupColumn = shouldUnderSameBlock(prev, groupKey)
            ? prev
            : getBlockColumn(sportType, isLive, groupKey, true);
        // no children means new group
        if (groupColumn.children!.length === 0) result.push(groupColumn);
        groupColumn.children!.push(column);
        groupColumn.width += column.width;
    });

    return result;
}

function getOutrightBlockColumn(blockType: OddsDisplayBlockType): BlockColumn {
    return {
        type: ColumnType.Block,
        key: blockType,
        width: OddsDisplayBlockTypeToColumnWidthMapping[blockType],
        children: undefined,
    };
}

export function getOddsTableColumnsOutright(isShowMore: boolean): OddsTableColumn[] {
    const timeColumn = getOutrightBlockColumn(OddsDisplayBlockType.Time);
    const teamColumn = getOutrightBlockColumn(OddsDisplayBlockType.Team);
    const moreColumn = getOutrightBlockColumn(OddsDisplayBlockType.More);

    const result = [
        timeColumn,
        teamColumn,
        ...(isShowMore ? [moreColumn] : []),
    ];
    // adjust team column to use all remaining space
    teamColumn.width = oddsDisplayWidth.value - result.filter(({ key }) => key !== OddsDisplayBlockType.Team).reduce((acc, cur) => acc + cur.width, 0);

    return result;
}

export function sortOdds(list: IOdds[], sportType: SportType) {
    if (sportType === SportType.Golf) {
        return orderBy(list, [
            odds => odds.sortOrder,
            odds => Math.abs(odds.point),
            odds => odds.point,
        ], ['desc', 'asc', 'asc']);
    }
    return orderBy(list, [
        odds => odds.sortOrder,
        odds => odds.point,
    ], ['desc', 'asc']);
}

export function getOddsTableMainMarket(
    oddsDisplayMode: OddsDisplayMode,
    event: IEvent,
    oddsList: IOdds[],
    displayMarketGroup: MarketGroup,
    isOddsLoaded: boolean,
) {
    const processedOddsList = oddsList.map((odds) => {
        if (is1X2(odds.marketType)) {
            const sortedPrices = orderBy(odds.prices, x => orderBy12X.indexOf(x.option.toUpperCase()));
            return { ...odds, prices: sortedPrices };
        }
        return odds;
    });
    const sortedOdds = sortOdds(processedOddsList, event.sportType);
    const groupedOddsList = groupBy(sortedOdds, (odds) => {
        const marketGroup = getMarketGroupId(odds);
        if (isMainMarket(displayMarketGroup)) {
            return isNextGoal(marketGroup)
                ? `${nextGoalMarketGroups[0]}-${odds.marketType}`
                : `${marketGroup}-${odds.marketType}`;
        }
        return odds.marketType;
    });

    const maxRowLength = isOddsLoaded
        ? Math.max(1, ...Object.values(groupedOddsList).map(x => x.length))
        : Math.max(1, ...Object.values(event.marketTypeCount[displayMarketGroup] ?? {}));

    const displayMarketTypeInfos = getDisplayMarketTypeInfos(event.sportType, event.isLive, oddsDisplayMode);
    const oddsColumns = displayMarketTypeInfos.map(({ marketGroup, marketType }) => {
        let marketOdds = [];
        if (isMainMarket(displayMarketGroup)) {
            marketOdds = groupedOddsList[`${marketGroup}-${marketType}`] || [];
        } else {
            marketOdds = isMainMarket(marketGroup) ? (groupedOddsList[marketType] || []) : [];
        }

        const prices = marketOdds.map(odds => odds.prices.map(price => ({
            odds,
            price,
            tag: getTag(odds, price),
            point: getOddsPoint(odds, price),
        })));
        return padRight(prices, maxRowLength, []);
    });

    const rows = rotate2dArray(oddsColumns);
    return rows.map(row => row.map((prices, index) => {
        const marketType = displayMarketTypeInfos[index].marketType;
        const marketGroup = displayMarketTypeInfos[index].marketGroup;
        const block = isMainMarket(marketGroup) ? MarketTypeGroupingMapping.get(marketType) : NewBetTypeGroupingMapping.get(marketGroup);

        return {
            prices,
            marketType,
            marketGroup,
            block,
        };
    }));
}

const availableBetBuilderSelectionStatus = [
    BetBuilderSelectionStatus.Running,
    BetBuilderSelectionStatus.Suspended,
    BetBuilderSelectionStatus.Incompatible,
];

export function getOddsTableBetBuilder(
    oddsDisplayMode: OddsDisplayMode,
    event: IEvent,
    betBuilderMarkets: IBetBuilderMarket[],
) {
    const displayMarketTypeInfos = getDisplayMarketTypeInfos(event.sportType, event.isLive, oddsDisplayMode);
    const betBuilderDisplayMarketTypeInfos = toBetBuilderMarketTypeInfos(displayMarketTypeInfos);

    const getGroupByKey = (statTypeId: number, betBuilderMarketTypeId: number) => `${BetBuilderStatType[statTypeId]}-${BetBuilderMarket[betBuilderMarketTypeId]}`;
    const groupedMarketList = groupBy(betBuilderMarkets, market => getGroupByKey(market.matchStatType.id, market.marketType.id));
    const maxRowLength = Math.max(1, ...Object.values(groupedMarketList).map(x => x.length));

    const marketTypeColumn = betBuilderDisplayMarketTypeInfos.map((marketTypeInfo) => {
        if (!marketTypeInfo) {
            return padRight([], maxRowLength, []);
        }

        const { statType, betBuilderMarketType } = marketTypeInfo;
        const key = getGroupByKey(statType, betBuilderMarketType);
        const markets = groupedMarketList[key] || [];
        const prices = markets.map((market) => {
            const marketRule = getMarketRule(market);
            return marketRule.selectionFormatter(market.selections)
                .map(selection => ({
                    market,
                    selection,
                    point: getSelectionPoint(market, selection.type.id),
                    isAvailable: 'id' in selection && availableBetBuilderSelectionStatus.includes(selection.status),
                }));
        });
        return padRight(prices, maxRowLength, []);
    });

    const rows = rotate2dArray(marketTypeColumn);
    return rows.map(row => row.map((selections, index) => {
        const marketType = displayMarketTypeInfos[index].marketType;
        const marketGroup = displayMarketTypeInfos[index].marketGroup;
        // to align with getOddsTableMainMarket
        const block = isMainMarket(marketGroup) ? MarketTypeGroupingMapping.get(marketType) : NewBetTypeGroupingMapping.get(marketGroup);

        return {
            selections,
            marketType,
            marketGroup,
            block,
        };
    }));
}

export function getMatchPlaceHolderHeight(oddsDisplayMode: OddsDisplayMode, isLive: boolean, event: IEvent, marketGroupId: MarketGroup) {
    const marketTypeList = getDisplayMarketTypeInfos(event.sportType, isLive, oddsDisplayMode).map(x => x.marketType);

    let threeRowCount = 0;
    let highestOtherOddsCount = 0;
    Object.entries(event.marketTypeCount[marketGroupId] ?? [])
        .forEach(([_marketType, count]) => {
            const marketType = parseInt(_marketType);
            if (!marketTypeList.includes(marketType)) return;

            const _is1X2 = is1X2(marketType);
            if (_is1X2 && count > threeRowCount) {
                threeRowCount = count;
            }

            if (!_is1X2 && count > highestOtherOddsCount) {
                highestOtherOddsCount = count;
            }
        });

    const twoRowCount = highestOtherOddsCount > threeRowCount ? highestOtherOddsCount - threeRowCount : 0;

    const threeRowHeight = 58;
    const twoRowHeight = 40;

    const placeholderHeight = threeRowCount * threeRowHeight + twoRowCount * twoRowHeight;
    return placeholderHeight;
}

const leagueTitleHeight = 20;

export function getLeaguePlaceHolderHeight(oddsDisplayMode: OddsDisplayMode, isLive: boolean, events: IEvent[], marketGroupId: MarketGroup) {
    const eventsPlaceholderHeight = events.reduce((acc, event) => acc + getMatchPlaceHolderHeight(oddsDisplayMode, isLive, event, marketGroupId), 0);
    return leagueTitleHeight + eventsPlaceholderHeight;
}

export function getOutrightMatchPlaceHolderHeight(league: IOutrightLeague) {
    const rowHeight = 22;
    const matchPlaceholderHeight = Math.ceil(league.oddsCount / 2) * rowHeight;
    return matchPlaceholderHeight;
}

export function getOutrightLeaguePlaceHolderHeight(league: IOutrightLeague) {
    return leagueTitleHeight + getOutrightMatchPlaceHolderHeight(league);
}

export function getMoreMarketPlaceHolderHeight(oddsDisplayMode: OddsDisplayMode, event: IEvent, marketGroupId: MarketGroup): number {
    const moreMarketCount = getMoreMarketCount(oddsDisplayMode, event, marketGroupId);
    if (moreMarketCount === 0) return 0;

    const isOpenMoreMarket = getIsOpenMoreMarket(oddsDisplayMode, event, marketGroupId);
    if (!isOpenMoreMarket) return 0;

    let estimatedMoreMarketPlaceHolderHeight = 0;
    if (moreMarketCount > 8) {
        estimatedMoreMarketPlaceHolderHeight = moreMarketCount * 40;
    } else if (moreMarketCount > 5) {
        estimatedMoreMarketPlaceHolderHeight = moreMarketCount * 55;
    } else {
        estimatedMoreMarketPlaceHolderHeight = moreMarketCount * 60;
    }

    return estimatedMoreMarketPlaceHolderHeight;
}
