import { nextGoalMarketGroups } from '@/core/lib/oddsHelper';
import type { MarketTypeInfo } from '@/interface/IMarketTypeInfo';
import { BetBuilderMarket, BetBuilderStatType, MarketGroup, MarketType, MarketTypeHeader, OddsDisplayMode, SportType } from '@/interface/enum';

type MarketTypeConfig = {
    [key in SportType]?: (MarketTypeInfo)[];
} & {
    default: (MarketTypeInfo)[];
}

export const MarketTypeBySportSingleLine: MarketTypeConfig = {
    default: [
        { marketGroup: MarketGroup.Main, marketType: MarketType.Handicap },
        { marketGroup: MarketGroup.Main, marketType: MarketType.OverUnder },
        { marketGroup: MarketGroup.Main, marketType: MarketType.FH_Handicap },
        { marketGroup: MarketGroup.Main, marketType: MarketType.FH_OverUnder },
    ],
};

const MarketTypeBySportDoubleLineLive: MarketTypeConfig = {
    [SportType.Soccer]: [
        { marketGroup: MarketGroup.Main, marketType: MarketType.LiveScore },
        { marketGroup: MarketGroup.Main, marketType: MarketType.Handicap },
        { marketGroup: MarketGroup.Main, marketType: MarketType.OverUnder },
        { marketGroup: MarketGroup.Main, marketType: MarketType._1X2 },
        { marketGroup: MarketGroup.Main, marketType: MarketType.FH_Handicap },
        { marketGroup: MarketGroup.Main, marketType: MarketType.FH_OverUnder },
        { marketGroup: MarketGroup.Main, marketType: MarketType.FH_1X2 },
        { marketGroup: nextGoalMarketGroups[0], marketType: MarketType.Handicap }, // Next goal
    ],
    [SportType.Cricket]: [
        { marketGroup: MarketGroup.Main, marketType: MarketType.Handicap },
        { marketGroup: MarketGroup.Main, marketType: MarketType._1X2 },
        { marketGroup: MarketGroup.HighestOpeningPartnership, marketType: MarketType._1X2 },
    ],
    default: [
        { marketGroup: MarketGroup.Main, marketType: MarketType.MoneyLine },
        { marketGroup: MarketGroup.Main, marketType: MarketType.Handicap },
        { marketGroup: MarketGroup.Main, marketType: MarketType.OverUnder },
        { marketGroup: MarketGroup.Main, marketType: MarketType.FH_Handicap },
        { marketGroup: MarketGroup.Main, marketType: MarketType.FH_OverUnder },
    ],
};

const MarketTypeBySportDoubleLineNonLive: MarketTypeConfig = {
    [SportType.Soccer]: [
        { marketGroup: MarketGroup.Main, marketType: MarketType.Handicap },
        { marketGroup: MarketGroup.Main, marketType: MarketType.OverUnder },
        { marketGroup: MarketGroup.Main, marketType: MarketType._1X2 },
        { marketGroup: MarketGroup.Main, marketType: MarketType.OddEven },
        { marketGroup: MarketGroup.Main, marketType: MarketType.FH_Handicap },
        { marketGroup: MarketGroup.Main, marketType: MarketType.FH_OverUnder },
        { marketGroup: MarketGroup.Main, marketType: MarketType.FH_1X2 },
    ],
    [SportType.Cricket]: [
        { marketGroup: MarketGroup.Main, marketType: MarketType.Handicap },
        { marketGroup: MarketGroup.Main, marketType: MarketType._1X2 },
        { marketGroup: MarketGroup.HighestOpeningPartnership, marketType: MarketType._1X2 },
    ],
    default: [
        { marketGroup: MarketGroup.Main, marketType: MarketType.MoneyLine },
        { marketGroup: MarketGroup.Main, marketType: MarketType.Handicap },
        { marketGroup: MarketGroup.Main, marketType: MarketType.OverUnder },
        { marketGroup: MarketGroup.Main, marketType: MarketType.OddEven },
        { marketGroup: MarketGroup.Main, marketType: MarketType.FH_Handicap },
        { marketGroup: MarketGroup.Main, marketType: MarketType.FH_OverUnder },
        { marketGroup: MarketGroup.Main, marketType: MarketType.FH_OddEven },
    ],
};

export function isSingleMode(oddsDisplayMode: OddsDisplayMode): boolean {
    return oddsDisplayMode === OddsDisplayMode.Single;
}

export function getDisplayMarketTypeInfos(sportType: SportType, isLive: boolean, oddsDisplayMode: OddsDisplayMode): MarketTypeInfo[] {
    if (isSingleMode(oddsDisplayMode)) {
        return MarketTypeBySportSingleLine[sportType] ?? MarketTypeBySportSingleLine.default;
    }

    const marketTypeListBySport = isLive ? MarketTypeBySportDoubleLineLive : MarketTypeBySportDoubleLineNonLive;

    return marketTypeListBySport[sportType] ?? marketTypeListBySport.default;
}

const fhMarketTypes = [
    MarketType.FH_1X2,
    MarketType.FH_Handicap,
    MarketType.FH_OverUnder,
    MarketType.FH_OddEven,
    MarketType.FH_CorrectScore,
    MarketType.FH_LiveScore,
];

const marketTypeBetBuilderMapping: Partial<Record<MarketType, BetBuilderMarket>> = {
    [MarketType._1X2]: BetBuilderMarket.Set1x2,
    [MarketType.FH_1X2]: BetBuilderMarket.Set1x2,
    [MarketType.DoubleChance]: BetBuilderMarket.SetDoubleChance,
    [MarketType.OverUnder]: BetBuilderMarket.SetTotalGoalsOverUnder,
    [MarketType.FH_OverUnder]: BetBuilderMarket.SetTotalGoalsOverUnder,
    [MarketType.OddEven]: BetBuilderMarket.SetTotalGoalsOddEven,
    [MarketType.FH_OddEven]: BetBuilderMarket.SetTotalGoalsOddEven,
    [MarketType.CorrectScore]: BetBuilderMarket.SetCorrectScore,
};

interface IBetBuilderMarketTypeInfo {
    marketType: MarketType;
    statType: BetBuilderStatType.FirstHalf | BetBuilderStatType.FullTime;
    betBuilderMarketType: BetBuilderMarket;
}

export function toBetBuilderMarketTypeInfos(displayMarketTypeInfos: MarketTypeInfo[]): (IBetBuilderMarketTypeInfo | undefined)[] {
    return displayMarketTypeInfos.map(({ marketType }) => {
        const betBuilderMarketType = marketTypeBetBuilderMapping[marketType];
        if (!betBuilderMarketType) {
            return undefined;
        }

        const statType = fhMarketTypes.includes(marketType)
            ? BetBuilderStatType.FirstHalf
            : BetBuilderStatType.FullTime;

        return {
            marketType,
            statType,
            betBuilderMarketType,
        };
    });
}

export const MarketTypeNameMapping: Partial<Record<MarketType|MarketTypeHeader, string>> = {
    [MarketType.Handicap]: 'hdp',
    [MarketType.FH_Handicap]: 'hdp',
    [MarketType.OverUnder]: 'ou',
    [MarketType.FH_OverUnder]: 'ou',
    [MarketType._1X2]: '1X2',
    [MarketType.FH_1X2]: '1X2',
    [MarketType.OddEven]: 'oe',
    [MarketType.FH_OddEven]: 'oe',
    [MarketType.TotalGoal]: 'total_goal',
    [MarketType.MoneyLine]: 'ml',
    [MarketType.LiveScore]: 'asian1X2',
    [MarketTypeHeader.More]: 'more',
};

export const MarketTypeTooltipMapping: Partial<Record<MarketType, string>> = {
    [MarketType.Handicap]: 'ft_hdp_tooltip',
    [MarketType.FH_Handicap]: 'fh_hdp_tooltip',
    [MarketType.OverUnder]: 'ft_ou_tooltip',
    [MarketType.FH_OverUnder]: 'fh_ou_tooltip',
    [MarketType._1X2]: 'ft_1X2_tooltip',
    [MarketType.FH_1X2]: 'fh_1X2_tooltip',
    [MarketType.OddEven]: 'ft_oe_tooltip',
    [MarketType.FH_OddEven]: 'fh_oe_tooltip',
    [MarketType.TotalGoal]: 'Total Goal_tooltip',
    [MarketType.MoneyLine]: 'ft_ml_tooltip',
    [MarketType.LiveScore]: 'asian1X2_tooltip',
};

export const MoreMarketTooltipMapping: Partial<Record<MarketType, string>> = {
    [MarketType.Handicap]: 'more_market_hdp_tooltip',
    [MarketType.FH_Handicap]: 'more_market_hdp_tooltip',
    [MarketType.OverUnder]: 'more_market_ou_tooltip',
    [MarketType.FH_OverUnder]: 'more_market_ou_tooltip',
    [MarketType._1X2]: 'more_market_1X2_tooltip',
    [MarketType.FH_1X2]: 'more_market_1X2_tooltip',
    [MarketType.OddEven]: 'more_market_oe_tooltip',
    [MarketType.FH_OddEven]: 'more_market_oe_tooltip',
    [MarketType.TotalGoal]: 'Total Goal_tooltip',
    [MarketType.MoneyLine]: 'more_market_ml_tooltip',
    [MarketType.LiveScore]: 'asian1X2_tooltip',
};

export const SingleLineMarketTypeTooltipMapping: Partial<Record<MarketType, string>> = {
    [MarketType.Handicap]: 'ft_hdp_point_tooltip',
    [MarketType.FH_Handicap]: 'fh_hdp_point_tooltip',
    [MarketType.OverUnder]: 'ft_goal_tooltip',
    [MarketType.FH_OverUnder]: 'fh_goal_tooltip',
};

export const HomeOptionTooltipMapping: Partial<Record<MarketType, string>> = {
    [MarketType.Handicap]: 'ft_hdp_home_tooltip',
    [MarketType.FH_Handicap]: 'fh_hdp_home_tooltip',
    [MarketType.OverUnder]: 'ft_ou_over_tooltip',
    [MarketType.FH_OverUnder]: 'fh_ou_over_tooltip',
};

export const AwayOptionTooltipMapping: Partial<Record<MarketType, string>> = {
    [MarketType.Handicap]: 'ft_hdp_away_tooltip',
    [MarketType.FH_Handicap]: 'fh_hdp_away_tooltip',
    [MarketType.OverUnder]: 'ft_ou_under_tooltip',
    [MarketType.FH_OverUnder]: 'fh_ou_under_tooltip',
};
