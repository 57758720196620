import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { NonNull, assignArray } from '@/core/lib/array';
import { deepFreeze } from '@/core/lib/utils';
import { useEventLiveScoresQuery } from '@/core/oddsApi/oddsApiType';
import type { IScoreInfo } from '@/interface/IScore';
import type { ISimpleEventResult } from '@/interface/ISimpleEventResult';
import { MarketGroup, SiteStyle } from '@/interface/enum';
import { useToggleStore } from '@/store/toggleStore';

const formatLiveScore = (item: NonNullable<NonNullable<ReturnType<typeof useEventLiveScoresQuery>['result']['value']>[number]>) => {
    const { id: eventId, mainMarketEventResult: { extraInfo, id: mainEventResultId, liveHomeScore, liveAwayScore }, eventResults } = item;
    const period = extraInfo?.period ?? 0;
    const periodStartTime = extraInfo?.periodStartTime ?? '0001-01-01T00:00:00';
    const getEventResultsScoreInfo = () => {
        const resultScoreInfos = eventResults.map<ISimpleEventResult>(result => ({
            id: result.id,
            homeTeam: {
                liveScore: result.liveHomeScore,
            },
            awayTeam: {
                liveScore: result.liveAwayScore,
            },
            marketGroup: {
                id: result.marketGroup?.id ?? MarketGroup.Main,
            },
        }));
        if (!resultScoreInfos.find(result => result.marketGroup.id === MarketGroup.Main)) {
            const mainScoreInfo = {
                id: mainEventResultId,
                homeTeam: {
                    liveScore: liveHomeScore,
                },
                awayTeam: {
                    liveScore: liveAwayScore,
                },
                marketGroup: {
                    id: MarketGroup.Main,
                },
            };
            return [...resultScoreInfos, mainScoreInfo];
        }
        return resultScoreInfos;
    };

    return {
        eventId,
        period,
        periodStartTime,
        eventResultsScoreInfo: getEventResultsScoreInfo(),
    };
};

export function useEventLiveScoreInfo(eventIds: Ref<number[]>, eventResultIds: Ref<number[]>) {
    const { liveScoreRefreshSeconds } = storeToRefs(useToggleStore(SiteStyle.Asia));

    const variables = computed(() => ({
        query: {
            ids: eventIds.value,
        },
        ids: eventResultIds.value,
    }));

    const { loaded, loading, onResult, refetch } = useEventLiveScoresQuery(
        variables,
        computed(() => ({
            pollInterval: liveScoreRefreshSeconds.value * 1000,
            enabled: eventIds.value.length > 0 && eventResultIds.value.length > 0,
        })),
    );

    const eventLiveScoreInfo = ref<IScoreInfo[]>([]);

    onResult((result) => {
        const formatted = (result ?? [])
            .filter(NonNull)
            .map(item => deepFreeze(formatLiveScore(item)));

        assignArray(eventLiveScoreInfo.value, formatted, item => item.eventId);
    });

    return {
        loaded,
        loading,
        refetch,
        eventLiveScoreInfo,
    };
}
