<template>
    <div
        v-debug:eventId="event.id"
        v-debug:eventResultId="eventResult?.id"
        class="matches notranslate"
        v-bind:class="{
            live: event.isLive,
            loading: !isOddsLoaded,
            showMore: moreMarketCount > 0 && isOpenMoreMarket,
            evenMatch: eventIndex % 2 === 0,
            singleLine: oddsDisplayMode === OddsDisplayMode.Single,
            doubleLine: oddsDisplayMode === OddsDisplayMode.Double,
            betBuilder: isInBetBuilderMode,
        }"
    >
        <div class="matches_row">
            <!-- time column-->
            <div
                class="matches_row_cell matchesTime"
                v-bind:style="{ width: getColumnWidth(OddsDisplayBlockType.Time) }"
            >
                <!-- live -->
                <div
                    v-if="event.isLive"
                    class="matchesTime_info matchesTime_info-live"
                >
                    <template v-if="displayScore">
                        <div
                            v-if="displayScore.cricketDisplayText"
                            class="matchesTime_info_Item score-cricket"
                        >
                            {{ displayScore.cricketDisplayText }}
                        </div>
                        <div
                            v-if="displayScore.setDisplayText"
                            class="matchesTime_info_Item liveDisplayTime"
                        >
                            {{ displayScore.setDisplayText }}
                        </div>
                        <div
                            v-if="displayScore.score"
                            class="matchesTime_info_Item score"
                            v-bind:class="{ 'score-change': isScoreChanged }"
                        >
                            {{ displayScore.score.home }}<span class="colon-padding">:</span>{{ displayScore.score.away }}
                        </div>
                        <div
                            v-if="displayScore.periodText"
                            class="matchesTime_info_Item liveDisplayTime"
                        >
                            {{ displayScore.periodText }}
                        </div>
                    </template>
                    <template v-else>
                        <div class="matchesTime_info_Item liveDisplayTime">
                            {{ i18n.t('live').toUpperCase() }}
                        </div>
                    </template>
                    <div
                        v-if="isShowInjuryTime(event)"
                        v-tooltip="i18n.t('injury_time')"
                        class="matchesTime_info_Item liveDisplayTimeAdd"
                    >
                        +{{ event.extraInfo?.injuryTime }}'
                    </div>
                </div>

                <!-- non-live -->
                <div
                    v-else
                    class="matchesTime_info matchesTime_info-nonLive"
                >
                    <div v-if="nonLiveTimeDisplay.date" class="matchesTime_info_Item">
                        {{ nonLiveTimeDisplay.date }}
                    </div>
                    <VTooltip>
                        <div
                            v-if="nonLiveTimeDisplay.time"
                            class="matchesTime_info_Item"
                        >
                            {{ nonLiveTimeDisplay.time }}
                        </div>
                        <div
                            v-if="nonLiveTimeDisplay.showLiveTag"
                            class="matchesTime_info_Item live-tag"
                            v-bind:class="{
                                'live-tag-red': event.isTvLive,
                            }"
                        >
                            <span>{{ i18n.t('live').toUpperCase() }}</span>
                        </div>
                        <template
                            v-if="event.tvChannels.length > 0"
                            #content
                        >
                            <ul>
                                <li
                                    v-for="tvChannel in tvChannels"
                                    v-bind:key="tvChannel"
                                >
                                    {{ tvChannel }}
                                </li>
                            </ul>
                        </template>
                    </VTooltip>
                </div>
            </div>

            <!-- event column -->
            <div
                class="matches_row_cell matchesEvent"
                v-bind:style="{ width: getColumnWidth(OddsDisplayBlockType.Team) }"
            >
                <!-- home vs away -->
                <div v-if="hasYesNoMarketGroup(mainMarketOdds)" class="matchesEvent_item">
                    <div class="team">
                        {{ event.homeTeam.name }} v.s {{ event.awayTeam.name }}
                    </div>
                </div>
                <!-- home -->
                <div class="matchesEvent_text">
                    <div
                        class="team"
                        v-bind:class="{
                            'team-favorite': isHomeFavorite(mainMarketOdds[0]?.eventResult),
                        }"
                    >
                        {{ getTeamName(TeamOption.Home, event, mainMarketOdds) }}
                    </div>
                    <FoulCard v-bind:count="redCard.home" />
                </div>
                <!-- away -->
                <div class="matchesEvent_text">
                    <div
                        class="team"
                        v-bind:class="{
                            'team-favorite': isAwayFavorite(mainMarketOdds[0]?.eventResult),
                        }"
                    >
                        {{ getTeamName(TeamOption.Away, event, mainMarketOdds) }}
                    </div>
                    <FoulCard v-bind:count="redCard.away" />
                </div>
                <!-- icons -->
                <div class="matchesEvent_item">
                    <Favorite
                        v-if="isShowFavorite"
                        type="EVENT"
                        v-bind:sport="event.sportType"
                        v-bind:eventId="event.id"
                        v-bind:leagueId="event.league.id"
                    />
                    <template v-if="isMainMarket(marketGroupId)">
                        <StatisticsIcon v-bind:event="event" />
                        <LiveCourtIcon
                            v-bind:event="event"
                            openPopupWindow
                        />
                        <LiveStreamIcon
                            v-bind:event="event"
                            openPopupWindow
                        />
                    </template>
                </div>
                <div
                    v-if="isBetBuilderMatchExist && isMainMarket(props.marketGroupId)"
                    v-gtm:type="'MCard'"
                    class="matchesEvent_item"
                >
                    <ToggleSwitch
                        v-gtm:label="isInBetBuilderMode ? 'BetBuilder_Off' : 'BetBuilder_On'"
                        v-gtm:@click
                        v-bind:modelValue="isInBetBuilderMode"
                        v-bind:text="`${i18n.t('market_type_name_41')}`"
                        v-on:update:modelValue="updateIsInBetBuilderMode"
                    />
                </div>
            </div>

            <div class="matches_subRows">
                <OddsDisplayMatchBodyBetBuilder
                    v-if="isInBetBuilderMode && isMainMarket(props.marketGroupId)"
                    v-bind:event="event"
                    v-bind:mainMarkets="betBuilderMainMarkets"
                    v-bind:moreMarkets="betBuilderMoreMarkets"
                    v-bind:isOpenMoreMarket="isOpenMoreMarket"
                    v-on:clickMore="toggleIsOpenMoreMarket()"
                />

                <OddsDisplayMatchBodyMain
                    v-else
                    v-bind:isOddsLoaded="isOddsLoaded"
                    v-bind:isMoreOddsLoaded="isMoreOddsLoaded"
                    v-bind:event="event"
                    v-bind:oddsList="oddsList"
                    v-bind:marketGroupId="marketGroupId"
                    v-bind:isOpenMoreMarket="isOpenMoreMarket"
                    v-on:clickMore="toggleIsOpenMoreMarket()"
                />
            </div>
        </div>
        <div v-if="!isOddsLoaded" class="loadingOverlay">
            <StaticSpinner />
        </div>
    </div>
    <template v-if="isOpenMoreMarket">
        <BetBuilderMoreMarket
            v-if="isMainMarket(marketGroupId) && isInBetBuilderMode && betBuilderMoreMarketCount"
            v-bind:event="event"
            v-bind:loading="!betBuilderMatchLoaded"
            v-bind:markets="betBuilderMoreMarkets"
        />
        <MoreMarket
            v-else-if="moreMarketCount > 0"
            v-bind:event="event"
            v-bind:loading="!isMoreOddsLoaded"
            v-bind:oddsList="moreMarketOdds"
            v-bind:outrightLeagues="outrightLeagues"
        />
    </template>
</template>

<script lang="ts" setup>
    import { autoResetRef } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import type { PropType } from 'vue';
    import { computed, toRefs, watch } from 'vue';
    import StaticSpinner from '@/components/common/StaticSpinner.vue';
    import FoulCard from '@/components/common/foulCard/FoulCard.vue';
    import ToggleSwitch from '@/components/common/toggleSwitch/ToggleSwitch.vue';
    import Favorite from '@/components/favorite/Favorite.vue';
    import { OddsDisplayContext } from '@/components/oddsDisplay/OddsDisplayContext';
    import OddsDisplayMatchBodyBetBuilder from '@/components/oddsDisplay/OddsDisplayMatchBodyBetBuilder.vue';
    import OddsDisplayMatchBodyMain from '@/components/oddsDisplay/OddsDisplayMatchBodyMain.vue';
    import { OddsDisplayMatchContext } from '@/components/oddsDisplay/OddsDisplayMatchContext';
    import LiveCourtIcon from '@/components/oddsDisplay/functions/LiveCourtIcon.vue';
    import LiveStreamIcon from '@/components/oddsDisplay/functions/LiveStreamIcon.vue';
    import StatisticsIcon from '@/components/oddsDisplay/functions/StatisticsIcon.vue';
    import { getBetBuilderDisplayMarkets, getMarketOdds, getMoreMarketCount, getNonLiveTimeDisplay, getTeamName, isAwayFavorite, isHomeFavorite, isShowInjuryTime } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
    import { useOpenMoreMarket } from '@/components/oddsDisplay/matchDisplay/useOpenMoreMarket';
    import BetBuilderMoreMarket from '@/components/oddsDisplay/moreMarket/BetBuilderMoreMarket.vue';
    import MoreMarket from '@/components/oddsDisplay/moreMarket/MoreMarket.vue';
    import { getOddsTableColumns } from '@/components/oddsDisplay/oddsTableCalculation';
    import { once } from '@/composable/once';
    import { useLiveScoreDisplay } from '@/composable/useLiveScoreDisplay';
    import { useLiveScoreRestriction } from '@/composable/useLiveScoreRestriction';
    import { i18n } from '@/core/lib/i18n';
    import { hasYesNoMarketGroup, isMainMarket } from '@/core/lib/oddsHelper';
    import { useLiveScore } from '@/core/oddsApi/composable/useLiveScore';
    import { getScore } from '@/core/oddsApi/helpers/liveScore';
    import { VTooltip } from '@/directives/tooltip';
    import type { IEvent } from '@/interface/IEvent';
    import type { IOdds } from '@/interface/IOdds';
    import type { IOutrightLeague } from '@/interface/IOutrightLeague';
    import type { IBetBuilderMarket } from '@/interface/betBuilder';
    import type { MarketGroup, MarketType } from '@/interface/enum';
    import { ColumnType, MarketPage, OddsDisplayBlockType, OddsDisplayMode, SiteStyle, TeamOption } from '@/interface/enum';

    import { useAsiaSportMarketInfoStore } from '@/store/asiaSportMarketInfoStore';
    import { useToggleStore } from '@/store/toggleStore';

    const props = defineProps({
        isOddsLoaded: {
            type: Boolean,
            required: true,
        },
        isMoreOddsLoaded: {
            type: Boolean,
            required: true,
        },
        event: {
            type: Object as PropType<IEvent>,
            required: true,
        },
        oddsList: {
            type: Array as PropType<IOdds[]>,
            required: true,
        },
        betBuilderMarkets: {
            type: Array as PropType<IBetBuilderMarket[]>,
            default: () => ([]),
        },
        marketGroupId: {
            type: Number as PropType<MarketGroup>,
            required: true,
        },
        outrightLeagues: {
            type: Array as PropType<IOutrightLeague[]>,
            default: () => [],
        },
        eventResultIndex: {
            type: Number as PropType<number | null>,
            default: () => null,
        },
    });

    const { isMyFavoriteEnabled } = storeToRefs(useToggleStore(SiteStyle.Asia));
    const { marketPage } = storeToRefs(useAsiaSportMarketInfoStore());
    const isShowFavorite = computed(() => isMyFavoriteEnabled.value && marketPage.value !== MarketPage.MixParlay);

    const { oddsDisplayMode, eventIndexMapping } = OddsDisplayContext.inject();
    const columns = computed(() => getOddsTableColumns(oddsDisplayMode, props.event.sportType, props.event.isLive));
    function getColumnWidth(key: OddsDisplayBlockType | MarketType, marketGroup?: MarketGroup) {
        return `${columns.value.find((x) => {
            if (x.type === ColumnType.Market) {
                return x.key === key && x.marketGroup === marketGroup;
            }
            return x.key === key;
        })?.width ?? 0}px`;
    }

    const marketOdds = computed(() => getMarketOdds(oddsDisplayMode, props.event, props.marketGroupId, props.oddsList));
    const mainMarketOdds = computed(() => marketOdds.value.main);
    const moreMarketOdds = computed(() => marketOdds.value.more);

    const { event } = toRefs(props);
    const { isOpenMoreMarket, toggleIsOpenMoreMarket } = useOpenMoreMarket(oddsDisplayMode, event, props.marketGroupId);

    const { isSupported } = useLiveScoreRestriction(event);
    const isEnableLiveScore = computed(() => isSupported.value && isMainMarket(props.marketGroupId));
    const { liveScore: providerLiveScore } = useLiveScore(event.value.id, isEnableLiveScore);
    const sboLiveScore = computed(() => getScore(props.event.eventResults, props.marketGroupId, props.event));
    const { displayScore } = useLiveScoreDisplay(event, providerLiveScore, sboLiveScore, SiteStyle.Asia);

    const isScoreChanged = autoResetRef(false, 5000);
    once(() => props.isOddsLoaded && props.event.eventResults.length > 0, () => {
        watch(sboLiveScore, (newValue, oldValue) => {
            if (newValue?.home !== oldValue?.home || newValue?.away !== oldValue?.away) {
                isScoreChanged.value = true;
            }
        });
    }, { immediate: true });

    const nonLiveTimeDisplay = computed(() => getNonLiveTimeDisplay(props.event));
    const tvChannels = computed(() => props.event.tvChannels.map(x => `${x.countryCode}: ${x.channelName}`));

    const eventIndex = computed(() => props.eventResultIndex ?? eventIndexMapping.value.get(props.event.id) ?? 0);

    const eventResult = computed(() => props.event.eventResults.find(x => x.marketGroup.id === props.marketGroupId));

    const redCard = computed(() => {
        if (isMainMarket(props.marketGroupId)) {
            return {
                home: props.event.homeTeam.redCard ?? 0,
                away: props.event.awayTeam.redCard ?? 0,
            };
        }

        return {
            home: eventResult.value?.homeTeam.redCard ?? 0,
            away: eventResult.value?.awayTeam.redCard ?? 0,
        };
    });

    const {
        isInBetBuilderMode,
        setIsInBetBuilderMode,
        isBetBuilderMatchExist,
        betBuilderMatchLoaded,
    } = OddsDisplayMatchContext.inject();
    const updateIsInBetBuilderMode = (value: boolean) => {
        setIsInBetBuilderMode(value);

        if (value) {
            toggleIsOpenMoreMarket(true);
        }
    };

    const betBuilderDisplayMarkets = computed(() => getBetBuilderDisplayMarkets(oddsDisplayMode, props.event, props.betBuilderMarkets));
    const betBuilderMainMarkets = computed(() => betBuilderDisplayMarkets.value.main);
    const betBuilderMoreMarkets = computed(() => betBuilderDisplayMarkets.value.more);

    const betBuilderMoreMarketCount = computed(() => betBuilderMoreMarkets.value.length);

    const moreMarketCount = computed(() => {
        if (isInBetBuilderMode.value) {
            return betBuilderMoreMarketCount.value;
        }

        return props.isOddsLoaded
            ? getMoreMarketCount(oddsDisplayMode, props.event, props.marketGroupId)
            : moreMarketOdds.value.length;
    });
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/OddsDisplayMatchBody";
</style>
