<template>
    <div class="moreMarket">
        <template
            v-for="(block, idx) in blocks"
            v-bind:key="idx"
        >
            <component
                v-bind:is="block.component"
                v-bind:event="event"
                v-bind:odds="block.oddsList[0]"
                v-bind:oddsList="block.oddsList"
            />
        </template>
        <Outright
            v-for="league in outrightLeagues"
            v-bind:key="league.id"
            v-bind:event="event"
            v-bind:league="league"
        />
        <StaticSpinner v-if="isContainsMoreMarket && loading" />
    </div>
</template>

<script lang="ts" setup>
    import entries from 'lodash/fp/entries';
    import flow from 'lodash/fp/flow';
    import groupBy from 'lodash/fp/groupBy';
    import map from 'lodash/fp/map';
    import orderByFp from 'lodash/fp/orderBy';
    import orderBy from 'lodash/orderBy';
    import { computed, toRefs } from 'vue';
    import type { Component } from 'vue';
    import StaticSpinner from '@/components/common/StaticSpinner.vue';
    import { OddsDisplayContext } from '@/components/oddsDisplay/OddsDisplayContext';
    import { mainMarketMoreOrder, useMoreMarketOddsDisplay } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
    import FastMarket from '@/components/oddsDisplay/moreMarket/marketType/FastMarket.vue';
    import MultipleBetType from '@/components/oddsDisplay/moreMarket/marketType/MultipleBetType.vue';
    import Outright from '@/components/oddsDisplay/moreMarket/marketType/Outright.vue';
    import SingleBetType from '@/components/oddsDisplay/moreMarket/marketType/SingleBetType.vue';
    import { isFastMarket, isFiveMinFastMarket, isMainMarket, isOneMinFastMarket } from '@/core/lib/oddsHelper';
    import type { IEvent } from '@/interface/IEvent';
    import type { IOdds } from '@/interface/IOdds';
    import type { IOutrightLeague } from '@/interface/IOutrightLeague';
    import { MarketGroup, MarketType } from '@/interface/enum';

    type IBlock = {
        oddsList: IOdds[];
        component: Component | null;
    }

    const MultipleMarketOrder = [
        MarketType.MoneyLine,
        MarketType.Handicap,
        MarketType.OverUnder,
        MarketType._1X2,
        MarketType.OddEven,
        MarketType.FH_Handicap,
        MarketType.FH_OverUnder,
        MarketType.FH_1X2,
        MarketType.FH_OddEven,
    ];

    const props = defineProps<{
        loading: boolean;
        event: IEvent;
        oddsList: IOdds[];
        outrightLeagues: IOutrightLeague[];
    }>();

    const { oddsDisplayMode } = OddsDisplayContext.inject();
    const { event, oddsList } = toRefs(props);
    const {
        mainMarketOddsList,
        newBetTypeOddsList,
        fastMarketOddsList,
    } = useMoreMarketOddsDisplay(oddsDisplayMode, event, oddsList);

    const isContainsMoreMarket = computed(() => Object.keys(props.event.marketTypeCount).some(x => x !== MarketGroup.Main.toString()));

    const isSingleOdds = (odds: IOdds) => odds.eventResult.marketGroup.isSingle;

    const mainOddsToBlocks: (list: IOdds[]) => IBlock[] = flow(
        orderByFp<IOdds>([
            odds => mainMarketMoreOrder.indexOf(odds.marketType),
            odds => odds.sortOrder,
            odds => odds.point,
        ], ['asc', 'desc', 'asc']),
        map((odds: IOdds) => ({
            oddsList: [odds],
            component: getComponent(odds),
        })),
    );

    const singleOddsToBlocks: (list: IOdds[]) => IBlock[] = flow(
        map((odds: IOdds) => ({
            oddsList: [odds],
            component: getComponent(odds),
        })),
    );

    const nonSingleOddsToBlocks: (list: IOdds[]) => IBlock[] = flow(
        groupBy(odds => odds.eventResult.marketGroup.id),
        map(oddsList => orderBy(oddsList, [
            odds => MultipleMarketOrder.indexOf(odds.marketType),
            odds => odds.sortOrder,
            odds => odds.point,
        ], ['asc', 'desc', 'asc'])),
        entries,
        map(([_, oddsList]) => {
            const component = getComponent(oddsList[0]);
            return ({ component, oddsList });
        }),
    );

    const fastMarketToBlocks: (list: IOdds[]) => IBlock[] = flow(
        groupBy((odds) => {
            if (isOneMinFastMarket(odds)) return 1;
            if (isFiveMinFastMarket(odds)) return 2;
            return 3;
        }),
        orderByFp<Array<IOdds>>([oddsList => Math.min(...oddsList.map(odds => odds.eventResult.marketGroup.displayPriority))], ['asc']),
        entries,
        map(([_, oddsList]) => ({ oddsList, component: FastMarket })),
    );

    const sortNewBetTypeBlocks: (list: IBlock[]) => IBlock[] = flow(
        orderByFp<IBlock>([
            block => block.oddsList[0].eventResult.marketGroup.displayPriority,
            block => block.oddsList[0].eventResult.id,
        ], ['asc', 'asc']),
    );

    const blocks = computed<IBlock[]>(() => {
        const mainBlocks = mainOddsToBlocks(mainMarketOddsList.value);

        const singleNewBetTypeMoreOddsList = newBetTypeOddsList.value.filter(odds => isSingleOdds(odds));
        const nonSingleNewBetTypeMoreOddsList = newBetTypeOddsList.value.filter(odds => !isSingleOdds(odds));
        const singleNewBetTypeBlocks = singleOddsToBlocks(singleNewBetTypeMoreOddsList);
        const nonSingleNewBetTypeBlocks = nonSingleOddsToBlocks(nonSingleNewBetTypeMoreOddsList);
        const newBetTypeBlocks = sortNewBetTypeBlocks([...singleNewBetTypeBlocks, ...nonSingleNewBetTypeBlocks]);

        const fastMarketBlocks = fastMarketToBlocks(fastMarketOddsList.value);

        return [
            ...mainBlocks,
            ...fastMarketBlocks,
            ...newBetTypeBlocks,
        ];
    });

    function getComponent(odds: IOdds): Component | null {
        if (isFastMarket(odds)) return FastMarket;
        if (isMainMarket(odds)) return SingleBetType;

        return isSingleOdds(odds)
            ? SingleBetType
            : MultipleBetType;
    }
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/moreMarket/MoreMarket";
</style>
