import type { MaybeRef } from '@vueuse/core';
import { get } from '@vueuse/core';
import { getNowWithGMT, parseISOString } from '@/core/lib/date';
import { i18n } from '@/core/lib/i18n';
import { isMainMarket, nextGoalMarketGroups } from '@/core/lib/oddsHelper';
import type { IEvent, IEventEuro } from '@/interface/IEvent';
import type { Score } from '@/interface/ILiveScore';
import type { ISimpleEventResult } from '@/interface/ISimpleEventResult';
import type { IStreamEvent } from '@/interface/IStream';
import { MarketGroup, MarketType, SiteStyle, SportType } from '@/interface/enum';

export function getLiveTimeDisplay(period: number, periodStartTime: string, siteStyle: SiteStyle) {
    if (period <= 0) {
        if (siteStyle === SiteStyle.Euro) {
            return i18n.t('live');
        }
        return i18n.t('live').toUpperCase();
    }
    if (isHalfTime(period)) {
        return i18n.t('ht');
    }

    const periodCurrentMinutes = getPeriodCurrentMinutes(periodStartTime);
    const quarter = period > 2 ? `${period - 2}E` : `${period}H`;
    const minutes = Math.min(Math.max(periodCurrentMinutes, 0), 99);
    return `${quarter} ${minutes}'`;
}

export function isHalfTime(eventOrPeriod: IEvent | IStreamEvent | number) {
    const period = typeof eventOrPeriod === 'number'
        ? eventOrPeriod
        : eventOrPeriod.extraInfo?.period;
    return period === 5;
}

export function formatLiveScore(liveHomeScore: number, liveAwayScore: number, delimiter: string) {
    return `${filterLiveScore(liveHomeScore)}${delimiter}${filterLiveScore(liveAwayScore)}`;
}

function filterLiveScore(score: number) {
    return score < 0 ? '--' : score;
}

function getPeriodCurrentMinutes(periodStartTime: string) {
    if (periodStartTime === '0001-01-01T00:00:00') {
        return 0;
    }

    // periodStartTime is using server time (GMT-4)
    const sec = getNowWithGMT(-4).getTime() - parseISOString(periodStartTime).getTime();
    return Math.floor(sec / (60 * 1000));
}

export const scoreUseMainMarket = [
    MarketGroup.Main,
    ...nextGoalMarketGroups,
];

export function isShowScoreInMoreMarket(event: MaybeRef<IEvent>, marketGroupId: MarketGroup): boolean {
    if (isMainMarket(marketGroupId)) return false;
    return get(event).isLive && get(event).sportType === SportType.Soccer;
}

export function isShowLiveScore(sportType: SportType, isLive: boolean, marketType: MarketType): boolean {
    if (marketType === MarketType.OutRight) return false;
    return sportType === SportType.Soccer && isLive;
}

// Avoid using 'eventResult' from odds for displaying scores.
// Otherwise, it may display a score of 0:0 when the odds are closed and the min row is 1.
export function getScore(
    _eventResults: MaybeRef<ISimpleEventResult[]>,
    _marketGroupId: MarketGroup,
    _event?: MaybeRef<IEvent | IEventEuro>,
): Score | null {
    const eventResults = get(_eventResults);
    const marketGroupId = get(_marketGroupId);
    const event = get(_event);
    const isUseMainScore = scoreUseMainMarket.includes(marketGroupId);
    const id = isUseMainScore ? MarketGroup.Main : marketGroupId;

    const eventResult = eventResults.find(x => x.marketGroup.id === id);
    if (
        eventResult
        && eventResult.homeTeam.liveScore !== null
        && eventResult.awayTeam.liveScore !== null
    ) {
        return {
            home: eventResult.homeTeam.liveScore,
            away: eventResult.awayTeam.liveScore,
        };
    }

    // If the event result is empty or not found for an unknown reason,
    // this can also prevent showing a score of 0:0 when `eventResults` have not yet loaded.
    if (
        id === MarketGroup.Main
        && event
        && event.homeTeam.liveScore !== null
        && event.awayTeam.liveScore !== null
    ) {
        return {
            home: event.homeTeam.liveScore,
            away: event.awayTeam.liveScore,
        };
    }

    return null;
}
