<template>
    <div v-debug:eventId="event.id" class="match notranslate">
        <div class="front">
            <div
                class="timeDate"
                v-bind:class="{
                    cricket: isCricket,
                }"
            >
                <template v-if="event.isLive">
                    <div class="matchInfo live">
                        <span
                            v-if="liveDisplayTime"
                            class="matchesTime_info_Item liveDisplayTime"
                        >
                            {{ liveDisplayTime }}
                        </span>
                        <span v-if="isShowInjuryTime(event)" class="injuryTime">
                            +{{ event.extraInfo?.injuryTime }}'
                        </span>
                    </div>
                    <div class="eventItems">
                        <LiveStreamIcon
                            v-bind:event="event"
                            v-bind:openPopupWindow="false"
                            v-on:click="goToMatchDetail(event, isMultiples, MatchDetailPreview.LiveStream)"
                        />
                        <LiveCourtIcon
                            v-bind:event="event"
                            v-bind:openPopupWindow="false"
                            v-on:click="goToMatchDetail(event, isMultiples, MatchDetailPreview.LiveCourt)"
                        />
                    </div>
                </template>
                <template v-else>
                    <div class="matchInfo">
                        {{ showTime.time }}
                    </div>
                    <div class="matchInfo">
                        {{ showTime.date }}
                    </div>
                </template>
            </div>
            <div class="teamName">
                <div class="team">
                    <div class="teamItem">
                        <strong class="teamItemRow">{{ event.homeTeam.name }}</strong>
                        <FoulCard v-bind:count="event.homeTeam.redCard ?? 0" />
                    </div>
                    <div class="teamItem">
                        <strong class="teamItemRow">{{ event.awayTeam.name }}</strong>
                        <FoulCard v-bind:count="event.awayTeam.redCard ?? 0" />
                    </div>
                </div>
                <div v-if="event.isLive" class="liveScoreItem">
                    <template v-for="item in liveScoreItems" v-bind:key="item.name">
                        <div v-if="item.isShow" class="score">
                            <strong v-bind:class="item.isSetScore ? 'setScoreItem' : 'scoreItem'">{{ item.homeScore }}</strong>
                            <strong v-bind:class="item.isSetScore ? 'setScoreItem' : 'scoreItem'">{{ item.awayScore }}</strong>
                        </div>
                    </template>
                </div>
                <div v-if="!event.isLive" class="eventItems">
                    <StatisticsIcon v-bind:event="event" />
                </div>
            </div>
        </div>
        <div
            class="oddsBoxGroup"
            v-bind:class="[oddsDirection]"
        >
            <div
                v-for="(odds, index) in displayOdds"
                v-bind:key="odds?.id ?? index"
                class="oddsBoxPair"
                v-bind:class="[priceDirection]"
            >
                <template v-if="odds">
                    <Odds
                        v-for="price in odds.prices"
                        v-bind:key="price.option"
                        v-bind:event="event"
                        v-bind:odds="odds"
                        v-bind:price="price"
                        fixedWidth
                        layout="vertical"
                        v-bind:betOption="getBetOption(odds, price)"
                    />
                </template>
                <!-- todo : apply loading style @Dilly-->
                <template v-else>
                    <div
                        v-for="i in (market.market === EuroMarket.GameLines
                            ? 2
                            : market.headers.length)"
                        v-bind:key="i"
                        v-bind:class="{
                            'oddsBox-loading': loading,
                            'oddsBox-empty': loaded,
                        }"
                    />
                </template>
            </div>
        </div>
        <div
            v-gtm:@click
            v-gtm:label="'More'"
            class="detail"
            v-on:click="goToMatchDetail(event, isMultiples)"
        >
            {{ moreCount }} <SvgIcon name="icon-system-arrow" v-bind:size="10" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import orderBy from 'lodash/orderBy';
    import { storeToRefs } from 'pinia';
    import { computed, toRefs } from 'vue';
    import SvgIcon from '@/components/common/SvgIcon.vue';
    import FoulCard from '@/components/common/foulCard/FoulCard.vue';
    import { EuroDisplayContext } from '@/components/euroDisplay/EuroDisplayContext';
    import Odds from '@/components/euroDisplay/Odds.vue';
    import { getMarketRule } from '@/components/euroDisplay/oddsDisplay/marketRules';
    import LiveCourtIcon from '@/components/oddsDisplay/functions/LiveCourtIcon.vue';
    import LiveStreamIcon from '@/components/oddsDisplay/functions/LiveStreamIcon.vue';
    import StatisticsIcon from '@/components/oddsDisplay/functions/StatisticsIcon.vue';
    import { isShowInjuryTime } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
    import { useEuroRouter } from '@/composable/useEuroRouter';
    import { useLiveScoreDisplay } from '@/composable/useLiveScoreDisplay';
    import { useLiveScoreRestriction } from '@/composable/useLiveScoreRestriction';
    import { useShowTimeDisplay } from '@/composable/useShowTimeDisplay';
    import { i18n } from '@/core/lib/i18n';
    import { useEventOddsEuro } from '@/core/oddsApi/composable/useEventOdds.euro';
    import { useLiveScore } from '@/core/oddsApi/composable/useLiveScore';
    import { getScore } from '@/core/oddsApi/helpers/liveScore';
    import type { IEventEuro } from '@/interface/IEvent';
    import type { IOdds, IPrice } from '@/interface/IOdds';
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    import {
        EuroMarket,
        MarketGroup,
        MatchDetailPreview,
        SiteStyle,
        SportType,
    } from '@/interface/enum';
    import { useCustomerStore } from '@/store/customerStore';

    const props = defineProps<{
        event: IEventEuro;
    }>();

    const { event } = toRefs(props);

    const { goToMatchDetail } = useEuroRouter();

    const showTime = computed(() => useShowTimeDisplay(event.value.showTime, event.value.showTimeType));

    const sboLiveScore = computed(() => getScore([], MarketGroup.Main, event.value));

    const { isSupported: isEnableLiveScore } = useLiveScoreRestriction(event);
    const { liveScore: providerLiveScore } = useLiveScore(event.value.id, isEnableLiveScore);
    const { displayScore } = useLiveScoreDisplay(event, providerLiveScore, sboLiveScore, SiteStyle.Euro);

    const liveDisplayTime = computed(() => {
        if (!event.value.isLive) return null;
        if (!displayScore.value) return i18n.t('live');
        if (displayScore.value.cricketDisplayText) return displayScore.value.cricketDisplayText;
        return displayScore.value.setDisplayText ? displayScore.value.setDisplayText : displayScore.value.periodText;
    });

    const liveScoreItems = computed(() => [
        {
            name: 'set',
            isShow: !!displayScore.value?.setScore,
            homeScore: displayScore.value?.setScore?.home,
            awayScore: displayScore.value?.setScore?.away,
            isSetScore: true,
        },
        {
            name: 'score',
            isShow: !!displayScore.value?.score,
            homeScore: displayScore.value?.score?.home,
            awayScore: displayScore.value?.score?.away,
            isSetScore: false,
        },
    ]);

    const moreCount = computed(() => Object.values(props.event.marketTypeCount).reduce((sum, current) => sum + Object.values(current).reduce((sum2, current2) => sum2 + current2, 0), 0));

    const { isMultiples, market } = EuroDisplayContext.inject();

    const isGameLines = computed(() => market.value.market === EuroMarket.GameLines);
    const oddsDirection = computed(() => (isGameLines.value ? 'row' : 'column'));
    const priceDirection = computed(() => (isGameLines.value ? 'column' : 'row'));

    const marketTypes = computed(() => market.value.marketTypes);
    const { priceStyle } = storeToRefs(useCustomerStore());
    const { oddsList, loaded, loading } = useEventOddsEuro({
        eventId: props.event.id,
        isLive: props.event.isLive,
        isMixParlay: isMultiples.value,
        marketTypes,
        priceStyle,
    });

    const displayOdds = computed(() => {
        const sorted = orderBy(oddsList.value, [o => o.sortOrder], ['desc']);
        return marketTypes.value.map(marketType => sorted.find(o => o.marketType === marketType));
    });

    const getBetOption = (odds: IOdds, price: IPrice) => {
        const rule = getMarketRule(odds.marketType);
        return rule.smallBetOptionFormatter(event.value, odds, price);
    };

    const isCricket = props.event.sportType === SportType.Cricket;
</script>

<style lang="scss" scoped>
@import "@/components/euroDisplay/Match";
</style>
