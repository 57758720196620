import type { ITeamNameDisplay } from '@/core/lib/betOptionDisplay';
import { getBetOptionDisplay } from '@/core/lib/betOptionDisplay';
import type { IBaseBet } from '@/interface/IBet';
import { MarketGroup, MarketType } from '@/interface/enum';

type DisplayOptionHandler = (bet: IBaseBet) => {
    name: string;
    isFavorite: boolean;
}

const marketRuleMap: Partial<Record<MarketType, DisplayOptionHandler>> = {
    [MarketType.Handicap]: displayHDP,
    [MarketType.FH_Handicap]: displayHDP,
    [MarketType.OddEven]: displayOE,
    [MarketType.FH_OddEven]: displayOE,
    [MarketType.OverUnder]: displayOU,
    [MarketType.FH_OverUnder]: displayOU,
    [MarketType.CorrectScore]: displayCS,
    [MarketType.FH_CorrectScore]: displayCS,
    [MarketType.DoubleChance]: displayDoubleChance,
    [MarketType.TotalGoal]: displayTotalGoal,
    [MarketType.HTFT]: displayHTFT,
    [MarketType.FGLG]: displayFGLG,
    [MarketType.MoneyLine]: displayML,
    [MarketType._1X2]: display1X2,
    [MarketType.FH_1X2]: display1X2,
    [MarketType.LiveScore]: displayAsianLiveScore,
    [MarketType.FH_LiveScore]: displayAsianLiveScore,
    [MarketType.OutRight]: displayOutright,
};

export function getMyBetOptionDisplay(bet: IBaseBet) {
    const displayFunc = marketRuleMap[bet.marketType];
    return displayFunc ? displayFunc(bet) : {
        name: bet.oddsOptionType,
        isFavorite: false,
    };
}

function displayHDP(bet: IBaseBet) {
    return {
        name: bet.betTeam,
        isFavorite: getBetOptionDisplay.isFavoriteTeam(bet.oddsOptionType, bet.favoriteTeam),
    };
}

function displayOE(bet: IBaseBet) {
    return getBetOptionDisplay.displayOE(bet.oddsOptionType);
}

function displayOU(bet: IBaseBet) {
    return getBetOptionDisplay.displayOU(bet.oddsOptionType, bet.betTypeGroup.id);
}

function displayCS(bet: IBaseBet) {
    return getBetOptionDisplay.displayCS(
        bet.oddsOptionType,
        bet.betLiveScore,
        bet.marketType,
        ':',
    );
}

function displayDoubleChance(bet: IBaseBet) {
    return getBetOptionDisplay.displayDoubleChance(bet.oddsOptionType);
}

function displayTotalGoal(bet: IBaseBet) {
    return getBetOptionDisplay.displayTotalGoal(bet.oddsOptionType, '~');
}

function displayHTFT(bet: IBaseBet) {
    return getBetOptionDisplay.displayHTFT(bet.oddsOptionType);
}

function displayFGLG(bet: IBaseBet) {
    return getBetOptionDisplay.displayFGLG(bet.oddsOptionType);
}

function displayML(bet: IBaseBet) {
    return {
        name: bet.betTeam,
        isFavorite: false,
    };
}

function display1X2(bet: IBaseBet) {
    const isMainBetType = bet.betTypeGroup.id === MarketGroup.Main;
    const teams = createTeamNameDisplay(bet.homeTeamName, bet.awayTeamName);
    return isMainBetType
        ? getBetOptionDisplay.display1X2(bet.oddsOptionType, teams)
        : getBetOptionDisplay.displayNewBetType1X2(bet.betTeam);
}

function displayAsianLiveScore(bet: IBaseBet) {
    const teams = createTeamNameDisplay(bet.homeTeamName, bet.awayTeamName);
    return getBetOptionDisplay.displayAsianLiveScore(
        bet.oddsOptionType,
        bet.marketType,
        bet.betLiveScore,
        teams,
    );
}

function displayOutright(bet: IBaseBet) {
    return {
        name: bet.betTeam,
        isFavorite: false,
    };
}

function createTeamNameDisplay(homeTeamName: string, awayTeamName: string): ITeamNameDisplay {
    return {
        homeTeamName,
        homeExtension: '',
        homeTemplate: '{home}',
        awayTeamName,
        awayExtension: '',
        awayTemplate: '{away}',
    };
}
