import type { MaybeRef } from '@vueuse/core';
import { get } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed, nextTick, ref, watch } from 'vue';
import { deepCompareComputed } from '@/composable/deepCompareComputed';
import { useState } from '@/composable/useState';
import { assignArray, remove, upsert } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import { isInvalidLiveMp1X2Odds, isValidOdds } from '@/core/lib/oddsHelper';
import { deepFreeze } from '@/core/lib/utils';
import { formatOdds } from '@/core/oddsApi/helpers/formatOdds';
import type {
    EnumOddsMarketFilterType,
    EnumPresetFilterType,
} from '@/core/oddsApi/oddsApiType';
import {
    EnumOddsCategoryType,
    useOddsQuery,
    useOddsSubscription,
} from '@/core/oddsApi/oddsApiType';
import type { IOdds } from '@/interface/IOdds';
import { EnumLanguage, PriceStyle } from '@/interface/enum';
import type { MarketGroup } from '@/interface/enum';
import { useBehaviorStore } from '@/store/behaviorStore';

export function useEventOdds({
    eventId,
    oddsId,
    presetFilter,
    oddsCategory,
    priceStyle,
    marketFilter,
    marketGroupIds,
    isActive = ref(true),
}: {
    eventId: number;
    oddsId?: number;
    presetFilter: EnumPresetFilterType;
    oddsCategory: EnumOddsCategoryType;
    priceStyle: MaybeRef<PriceStyle>;
    marketFilter: MaybeRef<EnumOddsMarketFilterType>;
    marketGroupIds?: MaybeRef<MarketGroup[] | null>;
    isActive?: Ref<boolean>;
}) {
    // sort here to generate a stable query
    const sortedMarketGroupIds = computed(() => {
        const _marketGroupIds = get(marketGroupIds);
        return _marketGroupIds ? _marketGroupIds.sort((a, b) => a - b) : null;
    });

    const variables = computed(() => ({
        query: {
            id: eventId,
            oddsId,
            filter: presetFilter,
            marketFilter: get(marketFilter),
            marketGroupIds: sortedMarketGroupIds.value,
            oddsCategory,
            priceStyle: PriceStyle.toOddsApi(get(priceStyle)),
            lang: EnumLanguage.toOddsApi(i18n.locale.value),
        },
    }));

    const [isEnabled, setIsEnabled] = useState(true);
    const refetch = async () => {
        if (isEnabled.value) {
            setIsEnabled(false);
            nextTick(() => setIsEnabled(true));
        }
    };

    const { loaded, loading, onResult } = useOddsQuery(
        variables,
        deepCompareComputed(() => ({
            enabled: isActive.value && isEnabled.value,
        })),
    );
    const { onSubscription } = useOddsSubscription(
        variables,
        deepCompareComputed(() => ({
            enabled: isActive.value,
        })),
    );

    const _oddsList = ref<IOdds[]>([]);
    const oddsList = computed(() => {
        const isMixParlayMode = oddsCategory === EnumOddsCategoryType.MixParlay;
        return _oddsList.value.filter(odds => isValidOdds(odds) && !isInvalidLiveMp1X2Odds(odds, isMixParlayMode));
    });

    const { addOddsQueryPerEventCount } = useBehaviorStore();
    onResult((result) => {
        const newOddsList = (result ?? [])
            .filter(odds => odds.eventResult?.marketGroup)
            .map(odds => deepFreeze(formatOdds(odds)));
        assignArray(_oddsList.value, newOddsList, item => item.id);

        addOddsQueryPerEventCount(eventId);
    });

    onSubscription((subscription) => {
        const toUpdate = (subscription?.updated ?? [])
            .filter(x => x.eventResult?.marketGroup)
            .map(x => deepFreeze(formatOdds(x)));

        upsert(_oddsList.value, toUpdate, item => item.id);
        remove(_oddsList.value, subscription?.deleted ?? [], (a, b) => a.id === b);
    });

    watch(isActive, (value) => {
        if (!value) {
            loaded.value = false;
        }
    });

    return {
        loaded,
        loading,
        refetch,
        oddsList,
    };
}
