<template>
    <div
        v-for="(row, rowIndex) in tableRows"
        v-bind:key="rowIndex"
        class="matches_subRow"
    >
        <template v-if="oddsDisplayMode === OddsDisplayMode.Single">
            <template
                v-for="(cells, cellsIndex) in row"
                v-bind:key="cellsIndex"
            >
                <div
                    class="matches_row_cell matches_row_cell-point"
                    v-bind:style="{ width: getColumnWidth(cells.marketType, cells.marketGroup) }"
                >
                    <div class="oddsItem">
                        <div class="oddsPoint">
                            {{ cells.prices.length > 0 ? formatPoint(cells.prices?.[0]?.odds.point ?? 0) : '' }}
                        </div>
                    </div>
                </div>
                <div
                    v-for="(cell) in cells.prices"
                    v-bind:key="`${cell.odds.id}-${cell.price.option}`"
                    class="matches_row_cell"
                    v-bind:style="{ width: getColumnWidth(cells.marketType, cells.marketGroup) }"
                >
                    <div class="oddsItem">
                        <Odds
                            v-bind:eventId="event.id"
                            v-bind:oddsId="cell.odds.id"
                            v-bind:oddsPrice="cell.price"
                            v-bind:oddsStatus="cell.odds.status"
                            class="oddsPrice"
                        />
                    </div>
                </div>
                <template v-if="cells.prices.length === 0">
                    <div
                        v-for="i in getPriceCount(cells.marketType)"
                        v-bind:key="i"
                        class="matches_row_cell"
                        v-bind:style="{ width: getColumnWidth(cells.marketType, cells.marketGroup) }"
                    />
                </template>
            </template>
        </template>
        <template v-if="oddsDisplayMode === OddsDisplayMode.Double">
            <div
                v-for="(cells, cellsIndex) in row"
                v-bind:key="cellsIndex"
                class="matches_row_cell"
                v-bind:style="{ width: getColumnWidth(cells.marketType, cells.marketGroup) }"
                v-bind:class="`group-${cells.block ?? `other`}`"
            >
                <div
                    v-for="i in 2"
                    v-if="cells.prices.length === 0"
                    v-bind:key="i"
                    class="oddsItem"
                />
                <div
                    v-for="cell in cells.prices"
                    v-bind:key="`${cell.odds.id}-${cell.price.option}`"
                    class="oddsItem"
                    v-bind:style="{
                        maxWidth: `${getColumnWidthLimit(cells.marketType, cells.marketGroup)}px`,
                    }"
                >
                    <div v-if="cell.point !== null" class="oddsPoint">
                        {{ cell.point }}
                    </div>
                    <div v-if="cell.tag !== null" class="oddsTag">
                        {{ cell.tag }}
                    </div>
                    <Odds
                        v-bind:eventId="event.id"
                        v-bind:oddsId="cell.odds.id"
                        v-bind:oddsPrice="cell.price"
                        v-bind:oddsStatus="cell.odds.status"
                        class="oddsPrice"
                    />
                </div>
            </div>
        </template>
        <div
            v-if="rowIndex === 0 && moreMarketCount > 0"
            class="matches_row_cell matchesMore"
            v-bind:class="{ showMore: isOpenMoreMarket }"
            v-bind:style="{ width: getColumnWidth(OddsDisplayBlockType.More) }"
        >
            <MoreButton
                v-gtm:label="'More'"
                v-gtm:@click
                v-bind:isOpenMore="isOpenMoreMarket"
                v-bind:count="moreMarketCount"
                v-on:click="emit('clickMore')"
            />
        </div>
        <div
            v-else
            class="matches_row_cell"
            v-bind:style="{ width: getColumnWidth(OddsDisplayBlockType.More) }"
        />
    </div>
</template>

<script lang="ts" setup>
    import { computed, toRefs } from 'vue';
    import MoreButton from '@/components/common/button/MoreButton.vue';
    import Odds from '@/components/odds/Odds.vue';
    import { OddsDisplayContext } from '@/components/oddsDisplay/OddsDisplayContext';
    import { useMatchBodyCalculation } from '@/components/oddsDisplay/matchBodyCalculation';
    import { getMarketOdds, getMoreMarketCount } from '@/components/oddsDisplay/matchDisplay/useMatchDisplay';
    import { getOddsTableMainMarket } from '@/components/oddsDisplay/oddsTableCalculation';
    import { formatPoint } from '@/core/lib/oddsHelper';
    import type { IEvent } from '@/interface/IEvent';
    import type { IOdds } from '@/interface/IOdds';
    import type { MarketGroup } from '@/interface/enum';
    import { OddsDisplayBlockType, OddsDisplayMode } from '@/interface/enum';

    const props = defineProps<{
        isOddsLoaded: boolean;
        isMoreOddsLoaded: boolean;
        event: IEvent;
        oddsList: IOdds[];
        marketGroupId: MarketGroup;
        isOpenMoreMarket: boolean;
    }>();

    const { event, isOpenMoreMarket } = toRefs(props);
    const { oddsDisplayMode } = OddsDisplayContext.inject();

    const { getColumnWidth, getColumnWidthLimit, getPriceCount } = useMatchBodyCalculation(oddsDisplayMode, event);

    const marketOdds = computed(() => getMarketOdds(oddsDisplayMode, props.event, props.marketGroupId, props.oddsList));
    const mainMarketOdds = computed(() => marketOdds.value.main);
    const moreMarketOdds = computed(() => marketOdds.value.more);
    const tableRows = computed(() => getOddsTableMainMarket(oddsDisplayMode, props.event, mainMarketOdds.value, props.marketGroupId, props.isOddsLoaded));

    const moreMarketCount = computed(() => (props.isOddsLoaded
        ? getMoreMarketCount(oddsDisplayMode, props.event, props.marketGroupId)
        : moreMarketOdds.value.length));

    const emit = defineEmits(['clickMore']);
</script>

<style lang="scss" scoped>
    @import "@/components/oddsDisplay/OddsDisplayMatchBody";
</style>
